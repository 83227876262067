import './NNProductScreen.css'
import React from 'react';
import {  
    Page,Flex,FlexItem,Badge,
    ButtonArea,
    Button,
    CellsTitle,
    CellsTips,
    Cell,
    CellHeader,
    CellBody,
    CellFooter,
    Form,
    FormCell,
    Icon,
    Input,
    Label,
    TextArea,
    Switch,
    Radio,
    Checkbox,
    Select,
    VCode,
    Agreement,
    Toptips,LoadMore} from 'react-weui';
import 'weui';
import { useParams } from 'react-router-dom'
import 'react-weui/build/packages/react-weui.css';
import axios from 'axios';
import GlobalTools from "./utils/GlobalTools";
import withRouter from "./withRouter"
import CityData from './utils/CityData';

class LTProductNumberList extends React.Component {

    constructor(props) {
        super(props);
       
  
    };

    render() {
        var that = this;
        let{
          nums,
          chooseNum,
          isShowChooseNums,
          onNumChoose,
          onSearchClick,
          handleInputChange,
          isNumsLoading,
          onChangeNumBatchClick,
          chooseNumItem,
          isShowRule
        }=this.props;
  

        if(!isShowChooseNums){
          return <></>;
        }

        return  <>
              <CellsTitle>选号</CellsTitle>
              <Form>
                  <FormCell vcode>
                          <CellHeader>
                              <Label>搜号码</Label>
                          </CellHeader>
                          <CellBody>
                              <Input type="text" placeholder="请输入喜欢的数字"   onChange={handleInputChange}  name="searchNum"  />
                          </CellBody>
                          <CellFooter>
                              <Button type="vcode" onClick={onSearchClick}>搜索</Button>
                          </CellFooter>
                  </FormCell>
              </Form>
              {isNumsLoading?<LoadMore loading>Loading</LoadMore>:""}
              <Flex className="weui-flex-num">
              {
                  nums.map((num)=>{
                      if(chooseNum==num.serialNumber){
                          return <FlexItem className="weui-flex-num__item choose-num" key={num.serialNumber} onClick={onNumChoose}>{num.serialNumber} <Badge dot preset="footer" /></FlexItem>
                      }
                      return <FlexItem className="weui-flex-num__item" key={num.serialNumber} onClick={onNumChoose}>{num.serialNumber}</FlexItem>
                  })

              }
              </Flex>

         
            
              <ButtonArea>
                <Button type="default" onClick={onChangeNumBatchClick}>换一批号码</Button>
              </ButtonArea>

              {chooseNumItem!=null?
              <>
               <CellsTitle>已选择</CellsTitle>
               <Form>
                    <FormCell>
                            <CellBody>
                                <p>    {chooseNumItem.serialNumber}</p>
                        
                               {isShowRule?<> <p>
                                低消： {chooseNumItem.monthFeeLimit}元/月    合约： {chooseNumItem.monthLimit}个月
                                </p></>:""}
                               
                            </CellBody> 

                            
                         
                    </FormCell>
              </Form>
              </>:""
            
             }
      </>
     }

 

}

export default withRouter(LTProductNumberList);