import React from 'react';

class ProductList extends React.Component {
    constructor(props) {
      super(props);
    }
  
    render() {

            return (<>
                {
                    this.props.products.map(li => 
                                <div className="pro-item" key={li.productVCode}> 
                                  <a href={`/ka/${li.productVCode}/${this.props.u}`}> 
                                    <img className="pro-img" src={li.productCoverUrl}></img>
                                    <span className='pro-title'>  {li.productTitle}</span> 
                                    </a>
                                </div>
                               
                            )
                }
        
            </>);
    }
  }
  
  export default ProductList;
