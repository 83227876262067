import '../App.css';
import './BusScreen.css';
import React from 'react';
import { Button,Page,Grids,Flex,FlexItem,   Footer,
    FooterText,
    FooterLinks,
    FooterLink,NavLink,InfiniteLoader,Article,
    Panel,
    PanelHeader,
    PanelBody,
    PanelFooter,
    MediaBox,
    MediaBoxHeader,
    MediaBoxBody,
    MediaBoxTitle,
    MediaBoxDescription,
    MediaBoxInfo,
    MediaBoxInfoMeta,
    Cells,
    Cell,
    CellHeader,
    CellBody,
    CellFooter} from 'react-weui';
import 'weui';
import 'react-weui/build/packages/react-weui.css';
import axios from 'axios';
import GlobalTools from "../utils/GlobalTools";
import TradeGrid from "./TradeGrid"
import TradeList from './TradeList';
import withRouter from "../withRouter"
import BusGrid from "./BusGrid"

const shouIcon = <img src="/images/shou.png" />
const chuIcon = <img src="/images/chu.png"/>;

 
class BusScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            page:1,
            trade:{}
        };

  

        console.log("构造器初始化");
    };

    
    componentWillMount(){

     }

    componentDidMount(){
        console.log("智能追踪推广员");
        var that  = this ;
        let {u,id}=that.props.router.params;
        console.log(that.props.router.params);
        this.setState({u:u});
        //持久化U
        GlobalTools.writeU(u);
        //确保使用https
        GlobalTools.fixHttps(); 
        console.log("初始化");
        axios.get( `/hopeapi/bus/${id}` )
        .then( function (response) {
            console.log("request done");
            //console.log(response);
            if (response.data.status && response.data.data ) {

                that.setState({trade:response.data.data});  
            }
        })
    
    }
    
    render() {
        var that = this;
        let {trade,u} = this.state;
        return (
         <Page title="号码宝神卡转让平台" subTitle="联通电信移动广电优惠套餐办理" infiniteLoader={true}
         onLoadMore={(resolve, finish) => this.onLoadMore(resolve, finish)} ptr={false} >
    
           <Panel>
                    <PanelBody>
                                <MediaBox type="appmsg" href={`/trade/bus/${trade.busCode}/${u}`}>
                            <MediaBoxHeader>{trade.busType==="转让"?chuIcon:shouIcon}</MediaBoxHeader>
                            <MediaBoxBody>
                                <MediaBoxDescription className="hmb-trade-content">
                                {trade.busContent}
                                </MediaBoxDescription>
                                <MediaBoxDescription className="hmb-trade-updatetime">
                                更新时间：{trade.displayUpdateTime}
                                </MediaBoxDescription>
                                <MediaBoxDescription className="hmb-trade-buscode">
                                信息编号：bus{trade.busCode}
                                </MediaBoxDescription>
                            </MediaBoxBody>
                        </MediaBox>
                    </PanelBody>
            </Panel>

            <BusGrid u={u}></BusGrid>
         </Page>
       );
    }

}


export default withRouter(BusScreen);