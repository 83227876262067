import './TradeList.css';
import React from 'react';
import { Button,Page,Grids,Flex,FlexItem,   Footer,
    FooterText,
    FooterLinks,
    FooterLink,NavLink,InfiniteLoader,Article,
    Panel,
    PanelHeader,
    PanelBody,
    PanelFooter,
    MediaBox,
    MediaBoxHeader,
    MediaBoxBody,
    MediaBoxTitle,
    MediaBoxDescription,
    MediaBoxInfo,
    MediaBoxInfoMeta,
    Cells,
    Cell,
    CellHeader,
    CellBody,
    CellFooter } from 'react-weui';
import 'weui';

const shouIcon = <img src="/images/shou.png" />
const chuIcon = <img src="/images/chu.png"/>;

class TradeList extends React.Component {

    constructor(props) {
        super(props);

    };

    
  onOrderCodeClick = (e)=>{
    console.log("onOrderCodeClick");
    console.log(e);
    this.props.onOrderCodeClick(e);
  }


  onExpressClick = (e)=>{
    //console.log("city被点击");
    //console.log(city);
    //console.log(this.props.cityCode);
    //console.log(this.props.cityName);
    console.log("onExpressClick");
    console.log(e);
    this.props.onExpressClick(e);
  }

    render() {

      let {u}= this.props;
 
      return  this.props.trades.map(trade =>
            <MediaBox type="appmsg" key={trade.busCode} href={`/trade/bus/${trade.busCode}/${u}`}>
                <MediaBoxHeader>{trade.busType==="转让"?chuIcon:shouIcon}</MediaBoxHeader>
                <MediaBoxBody>
                    <MediaBoxDescription className="hmb-trade-content">
                    {trade.busContent}
                    </MediaBoxDescription>
                    <MediaBoxDescription className="hmb-trade-updatetime">
                    {trade.displayUpdateTime}
                    </MediaBoxDescription>
                </MediaBoxBody>
            </MediaBox>
          );

    }

}


export default TradeList;