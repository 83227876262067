import React from 'react';
import {  
    Page,Flex,FlexItem,
    ButtonArea,
    Button,
    CellsTitle,
    CellsTips,
    Cell,
    CellHeader,
    CellBody,
    CellFooter,
    Form,
    FormCell,
    Icon,
    Input,
    Label,
    TextArea,
    Switch,
    Radio,
    Checkbox,
    Select,
    VCode,
    Agreement,
    Toptips} from 'react-weui';
import 'weui';
import { useParams } from 'react-router-dom'
import 'react-weui/build/packages/react-weui.css';
import axios from 'axios';
import GlobalTools from "./utils/GlobalTools";
import withRouter from "./withRouter"
import CityData from './utils/CityData';
import GDProductNumberList from './GDProductNumberList'
import { editableInputTypes } from '@testing-library/user-event/dist/utils';


class GDProductScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          product:{},
          imageId1PreviewUrl:'',
          imageId1File:'',
          showId1:false,
          imageId0PreviewUrl:'',
          imageId0File:'',
          showId0:false,
          imageIdxPreviewUrl:'',
          imageIdxFile:'',
          showIdx:false,
          showBtn:true,
          provinceList:CityData[86],
          cityList:[],
          areaList:[],
          province:"",
          city:"",
          area:"",
          u: this.props.router.params.u,
          belongCityCode:0,
          nums:[],
          chooseNum:'',
          isShowChooseNums:true,
          isNumsLoading:false,
          goodsTypeList:[{value:"选择套餐分类",label:"选择套餐分类"}],
          goodsType:"",
          goodsList:[{value:"选择套餐",label:"选择套餐"}],
          goods:"",
          searchModelIndex:0,
          searchNum:""
        };

        console.log("持久化U");
        //持久化U
        GlobalTools.writeU(this.props.router.params.u);
        
        //确保使用https
        GlobalTools.fixHttps();  

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleProvinceChange = this.handleProvinceChange.bind(this);
        this.handleCityChange = this.handleCityChange.bind(this);
        this.handleAreaChange = this.handleAreaChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleBelongProvinceChange = this.handleBelongProvinceChange.bind(this);
        this.handleBelongCityChange = this.handleBelongCityChange.bind(this);
        this.onSearchClick = this.onSearchClick.bind(this);
        this.onNumChoose = this.onNumChoose.bind(this);
        this.onChangeNumBatchClick = this.onChangeNumBatchClick.bind(this);
        this.handleGoodsTypeChange = this.handleGoodsTypeChange.bind(this);
        this.handleGoodsChange = this.handleGoodsChange.bind(this);
    };

    
    componentWillMount(){
 
     }

    componentDidMount(){
        console.log("产品页面-初始化产品");
        var that  = this ;

        //初始化产品
        if(this.props.product){
            this.setState({product:this.props.product});
        }else{
           this.iniProduct();
        }
      
        this.iniProvinceList();       
    }

    iniProduct(){
        var that = this;
        let {u,id}=that.props.router.params;
        console.log(that.props.router.params);
        this.setState({u:u});
        var cache = GlobalTools.autoCache();
        //持久化U
        GlobalTools.writeU(u);
        axios.get( `/hopeapi/product/${id}${cache}` )
        .then(function (response) {
            console.log("request done");
            console.log(response);
            if(response.data.status){
              that.setState({
                product: response.data.data
              })
            }else{
              that.showWarn("此产品已下架");
              //that.props.history.push("/big?u="+u);
            }
            
        })
        .catch(function(error){
           console.log(error)
        })
    }

    iniProvinceList(){
      console.log("加载广电省份信息")
      var that = this;
      axios.get( "/hopeapi/gd/area/top")
      .then(function (response) {
          console.log("request done");
          console.log(response);
          if(response.data){

            let provinceList = Object.values( response.data) .map(elem => {
              console.log(elem)
              return  {value:elem.areaName,label:elem.areaName};
            })


            that.setState({rawProvinceList:response.data});

            console.log("provinceList");
            console.log(provinceList);
            provinceList.unshift({value:"请选择省",label:"请选择省"})
            that.setState({
                provinceList:provinceList,
                belongCityList:[{value:"请选择市",label:"请选择市"}],
                cityList:[{value:"请选择市",label:"请选择市"}],
                areaList:[{value:"请选择区",label:"请选择区"}]
            });
            
          } 
      })
      .catch(function(error){
         console.log(error)
      })
      
    }

    handleInputChange(e){
        let o = {};
        o[e.target.name] = e.target.value;
        this.setState(o);
    }

    showWarn(warnInfo) {
      this.setState({showWarn: true,warnInfo:warnInfo});

      this.state.warnTimer = setTimeout(()=> {
          this.setState({showWarn: false,warnInfo:""});
      }, 2000);
  }
 
handleImageId1Change(e) {
    console.log("wa you want to upload");
    e.preventDefault();
      
    var reader = new FileReader();
    var file = e.target.files[0];
    
    reader.onloadend = () => {
      console.log('文件名为—',file);
      console.log('文件结果为—',reader.result);
      this.setState({
        imageId1File:file,
        imageId1PreviewUrl: reader.result,
        showId1:true
      });
    }
    
    reader.readAsDataURL(file) 
  
  }
  
  handleImageId0Change(e) {
    console.log("wa you want to upload");
    e.preventDefault();
      
    var reader = new FileReader();
    var file = e.target.files[0];
    
    reader.onloadend = () => {
      console.log('文件名为—',file);
      console.log('文件结果为—',reader.result);
      this.setState({
          imageId0File:file,
          imageId0PreviewUrl: reader.result,
          showId0:true
      });
    }
    
    reader.readAsDataURL(file) 
  
  }
  
  handleImageIdxChange(e) {
    console.log("wa you want to upload");
    e.preventDefault();
      
    var reader = new FileReader();
    var file = e.target.files[0];
    
    reader.onloadend = () => {
      console.log('文件名为—',file);
      console.log('文件结果为—',reader.result);
      this.setState({
         imageIdxFile:file,
         imageIdxPreviewUrl: reader.result,
         showIdx:true
      });
    }
    
    reader.readAsDataURL(file) 
  
  }
  
  uploadImage(api_url,file,orderCode,imageToken,nextJob){
    const formData = new FormData();
    console.log("开始上传照片------");
    // formData.append('file',value);
    formData.append('file', file)
    formData.append('token',imageToken);
    formData.append('orderCode',orderCode);
    let config = {
      method: 'post',
      headers:{'Content-Type': 'multipart/form-data'}
    }
    axios.post(api_url,formData,config).then((res) => {
      console.log("图片上传结果");
      console.log(res);
      if (res.status != 200) {
        alert("照片上传失败，请重试或者联系客服提交！");
        return false;
       }
      if (res.status === 200 && !res.data.status) {
   
        alert("照片上传失败，请重试或者联系客服提交！");
        return false;
      }
  
      //继续执行
      nextJob();
  
    }).catch((error) => {
      alert("照片上传失败，请重试或者联系客服提交！");
    })
  
  }
  
  uploadImageAll(uploadImageConfig,successJob,errorJob){
    const {api_url,imageId1File,imageId0File,imageIdxFile,orderCode,imageToken} = uploadImageConfig;
    const formData = new FormData();
    console.log("开始上传照片------");
    // formData.append('file',value);
    formData.append('id1', imageId1File);
    formData.append('id0', imageId0File);
    formData.append('idx', imageIdxFile);
    formData.append('token',imageToken);
    formData.append('orderCode',orderCode);
    let config = {
      method: 'post',
      headers:{'Content-Type': 'multipart/form-data'}
    }
    axios.post(api_url,formData,config).then((res) => {
      console.log("图片上传结果");
      console.log(res);
      if (res.status != 200) {
    
        errorJob();
        return false;
       }
      if (res.status === 200 && !res.data.status) {
   
      
        errorJob();
        return false;
      }
  
      //继续执行
      successJob();
  
    }).catch((error) => {
      errorJob();
   
    })
  
  }
  
  handleProvinceChange = (event)=>{
    const { value} = event.target;
    console.log("省发生变化");
    console.log(event);

    console.log(value);
    
    if(value==="请选择省"){
      this.setState({
        provinceCode: 0,
        provinceName: "",
        cityList: [{value:"请选择市",label:"请选择市"}],
        cityName: "请选择市",
        cityCode:0 
      });
      
      return;
    }

    //反查省code
    let provinceItem = Object.entries(this.state.rawProvinceList).filter(x => {
      console.log("检查");
        console.log(x);
        return value === x[1].areaName ;
    })
    console.log("反查到的省：");
    console.log(provinceItem);

    this.setState({
      provinceId: provinceItem[0][1].id,
      provinceCode: provinceItem[0][1].areaCode,
      provinceName:  provinceItem[0][1].areaName,
    });

    //加载城市 
    this.loadCityList(provinceItem[0][1].id);

  }
  
  handleCityChange = (event)=>{
    console.log(event);
    const {value} = event.target;
    console.log("市发生变化");
    console.log(event);
    console.log(value);

    if(value==="请选择市"){
      this.setState({
        cityId: 0,
        cityName: "请选择市",
        cityCode:0,
      });
      
      return;
    }

    console.log(this.state.rawCityList);

    //反查省code
    let cityItem = Object.entries(this.state.rawCityList).filter(x => {
      console.log("检查");
        console.log(x);
        return value === x[1].areaName ;
    })
    console.log("反查到的市：");
    console.log(cityItem);

    this.setState({
      cityId: cityItem[0][1].id,
      cityName: cityItem[0][1].areaName,
      cityCode:  cityItem[0][1].areaCode
    });


    let areaList = Object.values( cityItem[0][1].children ) .map(elem => {
      console.log(elem)
      return {value:elem.areaName,label:elem.areaName};
    })
    areaList.unshift({value:"请选择区",label:"请选择区"});
    console.log("区数据");
    console.log(areaList);

     this.setState({
       rawAreaList:cityItem[0][1].children,
       areaList: areaList,
       areaIndex: 0,
       areaCode:0,
       areaName:"请选择区"
     });


  }
  
  handleAreaChange = (event)=>{
    const {value} = event.target;
    var that = this;

     //取到区的名称
     if(value==="请选择区"){
      //区
      this.setState({
        areaIndex: 0,
        areaCode:0,
        areaName:""
      });
       return;
     }
      console.log("选择区："+value);
   
      //反查区code
      let areaItem = Object.entries(that.state.rawAreaList).filter(x => {
        console.log(x);
        return value === x[1].areaName ;
    })
    if(areaItem.length===0){
      //this.setState({province:""});
      return;
    }

    console.log("区信息：");
    console.log(areaItem);

    this.setState({
      areaId:areaItem[0][1].id,
      areaCode: areaItem[0][1].areaCode,
      areaName:areaItem[0][1].areaName
    });

  }
  
  handleSubmit = (event)=>{
    event.preventDefault() //阻止表单提交
    const {number,idname,idnum,address,chooseNum} = this.state;
    const {productPrice,productVCode,productTitle,productMode} = this.state.product;
    const {province,city,area,provinceName,cityName,areaName,
        provinceCode,cityCode,areaCode
        ,belongProvinceCode
        ,belongProvinceName
        ,belongCityCode
        ,belongCityName
        ,goodsName
        ,goodsId
        ,offerCode
        ,goodsSkuId

      } = this.state;
    var that = this;
    console.log("可以提交数据了");

    if( chooseNum ==="" || !/\d{11}/gi.test(chooseNum)){
      this.showWarn("请选号");
      return;
    }
   
    if(number==="" || !/\d{11}/gi.test(number)){
          //alert("请输入手机号码！");
          that.showWarn("请输入手机号码");
          return;
      }
    
  
      if(idname==="" ){
        //alert("请输入姓名！");
        that.showWarn("请输入姓名");
        return;
    }
    
    if(idnum==="" ){
      //alert("请输入证件号码！");
      that.showWarn("请输入证件号码");
      return;
  }
    
  if(province===-1 || city===-1 || area===-1){
    //alert("请选择省市区");
    that.showWarn("请选择省市区");
    return;
  }
  
  if(address==="" ){
    //alert("请输入收货地址！");
    that.showWarn("请输入收货地址");
    return;
  }
  
  if(address.length<7 ){
    //alert("详情地址不能少于7个汉字");
    that.showWarn("详情地址不能少于7个汉字");
    return;
  }
  
    //最终使用地址
    let finalAddress = provinceName+cityName+areaName+address;
    //如果当前业务为需要传照片的，则检查照片是否上传
    if(productMode==="image"){
        console.log(this.state.imageId1File);
        console.log(this.state.imageId0File);
        console.log(this.state.imageIdxFile);
    
        if(!this.state.imageId1File || !this.state.imageId0File || !this.state.imageIdxFile){
            //alert("请上传照片！");
            that.showWarn("请上传照片");
            return;
        }
    
    }
  
      let orderdata = new FormData();
      orderdata.append('idName',idname);
      orderdata.append('idNum',idnum);
      orderdata.append('receiveMobile',number);
      orderdata.append('phone',chooseNum);
      orderdata.append('address',address);
      orderdata.append('productVCode',productVCode);
      orderdata.append('productTitle',productTitle);
      orderdata.append('channel',this.state.u);
      orderdata.append('belongProvinceCode',belongProvinceCode);
      orderdata.append('belongProvinceName',belongProvinceName);
      orderdata.append('belongCityCode',belongCityCode);
      orderdata.append('belongCityName',belongCityName);
      orderdata.append('provinceCode',provinceCode);
      orderdata.append('provinceName',provinceName);
      orderdata.append('cityCode',cityCode);
      orderdata.append('cityName',cityName);
      orderdata.append('areaCode',areaCode);
      orderdata.append('areaName',areaName);
      orderdata.append('goodsName',goodsName);
      orderdata.append('goodsId',goodsId);
      orderdata.append('goodsSkuId',goodsSkuId);
      orderdata.append('offerCode',offerCode);


   console.log("请求参数:");
   console.log(orderdata);


    that.setState({
        showBtn:false
    });
    axios.post("/hopeapi/gd/order/newx",orderdata)
    .then(function (response) {
      console.log(response);
  
      if(response.status!==200){
        //alert("下单失败，稍后重试");
        that.showWarn("下单失败，稍后重试");
        that.setState({
          showBtn:true
         });
        return;
      }
    
      if(!response.data.status || response.data.status==="false"){
        alert(response.data.message);
        that.setState({
          showBtn:true
         });
        return;
      }

      that.setState({
        number:"",
        idname:'',
        idnum:"",
        address:"",
        province:-1,
        city:-1,
        area:-1
      });
      //如产品需要提交照片，则开始上传照片
      if(productMode==='image'){
          //"/hopeapi/biz/upload/id1"
          //  api_url,imageId1File,imageId0File,imageIdxFile,orderCode,imageToken
          var uploadImageConfig={
            api_url: "/hopeapi/biz/upload/ids",
            imageId1File: that.state.imageId1File,
            imageId0File: that.state.imageId0File,
            imageIdxFile: that.state.imageIdxFile,
            orderCode: response.data.data.orderCode,
            imageToken: response.data.data.imageToken
          }
          that.uploadImageAll(uploadImageConfig,function(){
            if(productPrice>0){
  
              that.setState({
                showBtn:true,
                showId1:false,
                showId0:false,
                showIdx:false
               });
               that.props.history.push("/pay/"+response.data.data.orderCode+"/"+response.data.data.imageToken);
            }
          },function(){
  
             //alert("照片上传失败，请重试或者联系客服提交！");
            that.setState({
              showBtn:true
             });
           
             that.props.history.push("/pay/"+response.data.data.orderCode+"/"+response.data.data.imageToken);
          });
  
      }else{
       
        if(productPrice>0){
          //alert("您下单的是付费产品，请联系页面底部平台客服，支付费用。");
          //跳到付款提示页面，拍出付款码/付款按扭
  
          that.setState({
            showBtn:true
           });
  
           that.props.router.navigate("/ok");
        }else{
          alert(response.data.message);
        }
      }
     
    });
  }
  
  handleBelongProvinceChange=(event)=>{
    console.log(event);
    const {name, value} = event.target;
    console.log("省发生变化");
    console.log(event);
     
    console.log(name);
    console.log(value);
    
    if(value==="请选择省"){
      this.setState({
        belongProvinceCode: 0,
        belongProvinceName: "",
        belongCityList: [{value:"请选择市",label:"请选择市"}],
        belongCityName: "请选择市",
        belongCityCode:0,
        nums:[]});
      
      return;
    }

    //反查省code
    let provinceItem = Object.entries(this.state.rawProvinceList).filter(x => {
      console.log("检查");
        console.log(x);
        return value === x[1].areaName ;
    })
    console.log("反查到的省：");
    console.log(provinceItem);

    this.setState({
      belongProvinceId: provinceItem[0][1].id,
      belongProvinceCode: provinceItem[0][1].areaCode,
      belongProvinceName:  provinceItem[0][1].areaName,
      nums:[]
    });


    this.loadGoodsType(provinceItem[0][1].areaCode);


    //加载城市
    this.loadBelongCityList(provinceItem[0][1].id);
 
  }

  handleBelongCityChange =(event)=>{
    console.log(event);
    const {name, value} = event.target;
    console.log("市发生变化");
    console.log(event);
    console.log(value);

    if(value==="请选择市"){
      this.setState({
        belongCityId: 0,
        belongCityName: "",
        belongCityCode:0,
        nums:[]});
      
      return;
    }

    console.log(this.state.rawBelongCityList);

    //反查省code
    let cityItem = Object.entries(this.state.rawBelongCityList).filter(x => {
      console.log("检查");
        console.log(x);
        return value === x[1].areaName ;
    })
    console.log("反查到的市：");
    console.log(cityItem);

    this.setState({
      belongCityId: cityItem[0][1].id,
      belongCityName: cityItem[0][1].areaName,
      belongCityCode:  cityItem[0][1].areaCode,
      nums:[]
    });

    //加载号码列表
    this.loadNumList(cityItem[0][1].areaCode);
  }

  loadBelongCityList=(provinceId)=>{
    console.log("加载广电城市信息")
    var that = this;
    axios.get( `/hopeapi/gd/area/${provinceId}`)
    .then(function (response) {
        console.log("request done");
        console.log(response);
        if(response.data){

          let belongCityList = Object.values( response.data.children) .map(elem => {
            console.log(elem)
            return  {value:elem.areaName,label:elem.areaName};
          })

          that.setState({rawBelongCityList:response.data.children});

          console.log("belongCityList");
          console.log(belongCityList);
          belongCityList.unshift({value:"请选择市",label:"请选择市"})
          that.setState({
              belongCityList:belongCityList,
              belongCityName: "请选择市",
              belongCityCode:0,
          });
          
        } 
    })
    .catch(function(error){
       console.log(error)
       that.setState({
        belongCityName: "请选择市",
        belongCityCode:0,
    });
    })
  }

  loadCityList=(provinceId)=>{
    console.log("加载广电城市信息")
    var that = this;
    axios.get( `/hopeapi/gd/area/${provinceId}`)
    .then(function (response) {
        console.log("request done");
        console.log(response);
        if(response.data){

          let cityList = Object.values( response.data.children) .map(elem => {
            console.log(elem)
            return  {value:elem.areaName,label:elem.areaName};
          })

          that.setState({rawCityList:response.data.children});

          console.log("cityListUI");
          console.log(cityList);
          cityList.unshift({value:"请选择市",label:"请选择市"})
          that.setState({
              cityList:cityList,
              cityName: "请选择市",
              cityCode:0,
              areaList:[{value:"请选择区",label:"请选择区"}],
              areaName: "请选择区",
              areaCode:0,
          });
          
        } 
    })
    .catch(function(error){
       console.log(error)
       that.showWarn("地址信息加载失败，请刷新重试");
       that.setState({
        cityList:[{value:"请选择市",label:"请选择市"}],
        cityName: "请选择市",
        cityCode:0,
        areaList:[{value:"请选择区",label:"请选择区"}],
        areaName: "请选择区",
        areaCode:0,
    });
    })
  }


  loadNumList =(belongCityCode)=>{
    console.log("加载可选号码")
    var that = this;
    console.log(this);
    if (that.state.isNumsLoading ) {
        return
    }
    console.log("加载可选号码1")
   that.setState({isNumsLoading:true});

   console.log("加载可选号码")
   let orderdata = new FormData();
    orderdata.append('regionId',belongCityCode);
    orderdata.append('page',1);
    orderdata.append('fuzzyTag',that.state.searchModelIndex);
    orderdata.append('fuzzyKey',that.state.searchNum);


   axios.post( `/hopeapi/gd/numauto`,orderdata)
   .then(function (response) {
       console.log("request done");
       console.log(response);
       if(response.data){

         let nums = Object.values( response.data.result.numList) .map(elem => {
           //console.log(elem)
           return  elem.accessNum;
         })

         that.setState({nums:nums,isShowChooseNums:true});
       } 
   }).finally(function(){
    that.setState({isNumsLoading:false});
   })
  
  }

  onSearchClick =(e) =>{
    let{searchNum,belongCityCode}=this.state;
  
    if(belongCityCode===0){
      this.showWarn("请选归属地");
      return;
    }

    console.log("searchNum:"+searchNum);
    if(!/\d{1,11}/gi.test(searchNum)){
        //alert("请输入手机号码！");
        this.showWarn("仅搜数字");
        return;
    }  

    console.log("归属地城市编号：");
    console.log(belongCityCode);
     
    this.loadNumList(belongCityCode);

  }
  onNumChoose=(e)=>{
    console.log(e);
    this.setState({chooseNum:e.target.textContent})
  }

  onChangeNumBatchClick=()=>{
    let{searchNum,belongCityCode}=this.state;
    console.log("searchNum:"+searchNum);
    console.log("belongCityCode:"+belongCityCode);

    if(belongCityCode===0){
      this.showWarn("请选归属地");
      return;
    }

    if(searchNum===""){
        this.loadNumList(belongCityCode);
        return;
    }
    if(!/\d{3,11}/gi.test(searchNum)){
        //alert("请输入手机号码！");
        this.showWarn("至少输3个数字");
        return;
    }  
      
    this.loadNumList(belongCityCode);

  }

  loadGoodsType=(provinceCode)=>{
    console.log("加载广电套餐类别:"+provinceCode)
    var that = this;
    axios.get( `/hopeapi/gd/goodslist/${provinceCode}`)
    .then(function (response) {
        console.log("加载广电套餐类别 done");
        console.log(response);
        if(response.data){
          let goodsTypeList = Object.values( response.data.result.goodsList).map(elem => {
            console.log(elem)
            return  {value:elem.goodsName,label:elem.goodsName};
          })

          goodsTypeList.unshift({value:"选择套餐分类",label:"选择套餐分类"});
          that.setState({goodsTypeList:goodsTypeList,rawGoodsTypeList:response.data.result.goodsList}); 
        } 
    })
    .catch(function(error){
       console.log(error)
    })
  }

  handleGoodsTypeChange =(event)=>{
    console.log("已选择套餐类型");
    console.log(event);
    const {value} = event.target;
    console.log("套餐类别发生变化");
    console.log(value);
    
    if(value==="选择套餐分类"){
      this.setState({
        goodsType: "选择套餐分类",
        goods:"选择套餐"
      });
      
      return;
    }

    this.setState({
      goodsType: value
    });


    //反查套餐分类对象
    let goodsTypeItem = Object.entries(this.state.rawGoodsTypeList).filter(x => {
      return value === x[1].goodsName ;
    })
    console.log("反查套餐分类：");
    console.log(goodsTypeItem);
    if(goodsTypeItem.length===0){
      //this.setState({province:""});
      return;
    }

    this.setState({
      goodsTypeId: goodsTypeItem[0][1].id,
      goodsTypeName:value
    });
    
    let goodsList = Object.values( goodsTypeItem[0][1].goodsChildList ) .map(elem => {
      console.log(elem)
      return  {value:elem.childName,label:elem.childName};;
    })

    console.log("套餐数据");
    console.log(goodsList);
    goodsList.unshift({value:"选择套餐",label:"选择套餐"});
    this.setState({
      rawGoodsList:goodsTypeItem[0][1].goodsChildList,
      goodsList: goodsList,
      goods: "选择套餐",
      goodsSkuId: "",
      goodsId: "",
      goodsName:"选择套餐"
    });
  }

  handleGoodsChange = (event)=>{
    console.log("已选择套餐");
    console.log(event);
    const {value} = event.target;
    console.log("套餐发生变化");
    console.log(value);
    
    if(value==="选择套餐"){
      this.setState({
        goods: "选择套餐",
        goodsSkuId: "",
        goodsId: "",
        goodsName:"选择套餐"
      });
      
      return;
    }

    console.log("选择套餐："+value);
    console.log(this.state.rawGoodsList);
   //反查套餐分类对象
   let goodsItem = Object.entries(this.state.rawGoodsList).filter(x => {
       return value === x[1].childName ;
   })
   console.log("反查套餐：");
   console.log(goodsItem);
   if(goodsItem.length===0){
     //this.setState({province:""});
     return;
   }
   this.setState({
     goodsSkuId: goodsItem[0][1].id,
     goodsId: goodsItem[0][1].goodsId,
     goodsName:value,
     goods:value,
     offerCode:null
   });

   console.log("促销活动");
   console.log(goodsItem[0][1].activities);

   if(goodsItem[0][1].activities && goodsItem[0][1].activities.length>0){
    this.setState({
      offerCode:goodsItem[0][1].activities[0].offerCode
    });
   }

  }
 
    render() {
        var that = this;
        let{nums,chooseNum,isShowChooseNums,isNumsLoading} = this.state;
        let{onSearchClick,handleInputChange,onNumChoose,onChangeNumBatchClick} = this;

        return (
            <Page className="input" title="号码宝特色卡种" subTitle="联通电信移动广电优惠套餐办理" infiniteLoader={false}
             ptr={false}  >       
            <img src={this.state.product.productCoverUrl} style={{width:"100%"}}/>
            <div className='hmbProductTitle'>{this.state.product.productTitle}</div>

            <CellsTitle>归属地</CellsTitle>
            <Form>
                 <FormCell select selectPos="after">
                    <CellHeader>
                        <Label>省</Label>
                    </CellHeader>
                    <CellBody>
                        <Select data={this.state.provinceList}  onChange={this.handleBelongProvinceChange} />
                    </CellBody>
                </FormCell>

                <FormCell select selectPos="after">
                    <CellHeader>
                        <Label>市</Label>
                    </CellHeader>
                    <CellBody>
                        <Select data={this.state.belongCityList}  value={this.state.belongCityName} onChange={this.handleBelongCityChange} />
                    </CellBody>
                </FormCell>

            </Form>

            <GDProductNumberList 
            nums={nums} 
            chooseNum={chooseNum} 
            isShowChooseNums={isShowChooseNums} 
            isNumsLoading={isNumsLoading}

            onSearchClick = {onSearchClick}
            handleInputChange ={handleInputChange}
            onNumChoose={onNumChoose}
            onChangeNumBatchClick = {onChangeNumBatchClick}

            ></GDProductNumberList>

            <CellsTitle>基本信息</CellsTitle>
            <Form>
                    <FormCell>
                        <CellHeader>
                            <Label>姓名</Label>
                        </CellHeader>
                        <CellBody>
                            <Input type="text" placeholder="输入姓名" onChange={this.handleInputChange}  name="idname"/>
                        </CellBody>
                    </FormCell>

                    <FormCell>
                        <CellHeader>
                            <Label>身份证号</Label>
                        </CellHeader>
                        <CellBody>
                            <Input type="text" placeholder="输入身份证号"  onChange={this.handleInputChange}   name="idnum" />
                        </CellBody>
                    </FormCell>

                    <FormCell>
                        <CellHeader>
                            <Label>联系号码</Label>
                        </CellHeader>
                        <CellBody>
                            <Input type="text" placeholder="输入联系号码"  onChange={this.handleInputChange}   name="number" />
                        </CellBody>
                    </FormCell>

                 
            </Form>

            <CellsTitle>收货地址</CellsTitle>
            <Form>
                 <FormCell select selectPos="after">
                    <CellHeader>
                        <Label>省</Label>
                    </CellHeader>
                    <CellBody>
                        <Select data={this.state.provinceList}  onChange={this.handleProvinceChange} />
                    </CellBody>
                </FormCell>

                <FormCell select selectPos="after">
                    <CellHeader>
                        <Label>市</Label>
                    </CellHeader>
                    <CellBody>
                        <Select data={this.state.cityList} value={this.state.cityName}  onChange={this.handleCityChange} />
                    </CellBody>
                </FormCell>

                <FormCell select selectPos="after">
                    <CellHeader>
                        <Label>区</Label>
                    </CellHeader>
                    <CellBody>
                        <Select data={this.state.areaList} value={this.state.areaName}  onChange={this.handleAreaChange} />
                    </CellBody>
                </FormCell>

                <FormCell>
                        <CellHeader>
                            <Label>详情地址</Label>
                        </CellHeader>
                        <CellBody>
                            <Input type="text" placeholder="至少7个汉字 勿重复省市区" onChange={this.handleInputChange}   name="address" />
                        </CellBody>
                    </FormCell>

            </Form>

            <div style={{ display: 'none' }}>
            <CellsTitle>套餐选择</CellsTitle>
            <Form>
                 <FormCell select selectPos="after">
                    <CellHeader>
                        <Label>套餐类型</Label>
                    </CellHeader>
                    <CellBody>
                        <Select data={this.state.goodsTypeList} value={this.state.goodsType}  onChange={this.handleGoodsTypeChange} />
                    </CellBody>
                </FormCell>

                <FormCell select selectPos="after">
                    <CellHeader>
                        <Label>套餐</Label>
                    </CellHeader>
                    <CellBody>
                        <Select data={this.state.goodsList}  value={this.state.goods} onChange={this.handleGoodsChange} />
                    </CellBody>
                </FormCell>
            </Form>
            </div>
            
            <ButtonArea>
                     <Button onClick={this.handleSubmit}  style={{ display: this.state.showBtn?'block':'none' }}>
                         {this.state.product.productPrice}元{this.state.product.productBtn}
                     </Button>


                     <Button style={{ display: !this.state.showBtn?'block':'none' }}>
                        正在提交，请稍后
                    </Button>


            </ButtonArea>
            
            <img src={this.state.product.productDetailUrl} style={{width:"100%",marginTop: '0.5em'}}/>
            <img src="/warnnotice.jpg" style={{width:"100%"}}/>

            <Toptips type="warn" show={this.state.showWarn}> {this.state.warnInfo} </Toptips>
         </Page>
       );
    }

}


export default withRouter(GDProductScreen);