'use strict';
import Url from 'url';

const defaultU = "zuime";

const fixHttps = () =>{
    console.log("验证https");  
    console.log(window);
    if(window.location.hostname!=="localhost"){
        //确保是https
        if(window.location.protocol==="http:"){
        console.log("自动跳转到https 确保用户数据安全");  
        window.location.href=window.location.href.replace("http://","https://")
        }
   }
} 

const fixUrl =(u)=>{
    console.log("自动将u放到url");
    console.log(window);
    console.log(window.location.href);
    let finder = Url.parse(window.location.href,true); 
    console.log(finder);
    if(!finder.query.u){
       console.log("需要修复url");
       window.location = `/?u=${u}`
       // navigate('/?u=${u}');
    }else{
        console.log("u已经在url中，无需重复修复");
    }
}

const getUFromLocal = () =>{
       console.log("尝试从本地恢复U");
       try {
        //尝试读取本地追踪标
        var local_u = localStorage.getItem("u");//获取存储的元素
        if(local_u){
            console.log("本地缓存有追踪标，重置默认推广员");
            //that.setState({u:local_u});
            return local_u;
        }
        }
        catch(err) {
           console.log("读取追踪标出现了异常");
           return defaultU;
        }
        console.log("使用默认U");
        return defaultU;
}

const autoU = (search,callback)=>{

    let finder = Url.parse(search,true); 
    let u = "zuime";
    console.log(search);
    console.log("Url解析结果");
    console.log(finder.query);
    if(finder.query.u){
        console.log("url参数中有u信息");
        //this.setState({u:finder.query.u});
        u = finder.query.u;
        if(u==="undefined"){
            u = getUFromLocal();
        }else{
            try {
                //写入本地永久缓存
                localStorage.setItem("u",finder.query.u);//写入本地 追踪标
            }
            catch(err) {
                console.log("写入本地追踪标出现了异常");
            }
        }
    }else{
        u = getUFromLocal();
    }

    console.log("智能识别出来的u:"+u);
    if(typeof(callback) === 'function'){
        callback({ u:u});
     }
}

const writeU =(u)=>{
    try {
        //写入本地永久缓存
        localStorage.setItem("u",u);//写入本地 追踪标
    }
    catch(err) {
        console.log("写入本地追踪标出现了异常");
    }
}

const autoCache = ()=>{
    console.log("智能cahce处理");
    return "";
}

const GlobalTools ={
    fixHttps,
    autoU,
    writeU,
    fixUrl,
    autoCache
}


export default GlobalTools;