import React from 'react';
import {  
    Page,Flex,FlexItem,
    ButtonArea,
    Button,
    CellsTitle,
    CellsTips,
    Cell,
    CellHeader,
    CellBody,
    CellFooter,
    Form,
    FormCell,
    Icon,
    Input,
    Label,
    TextArea,
    Switch,
    Radio,
    Checkbox,
    Select,
    VCode,
    Agreement,
    Toptips,Article,Cells} from 'react-weui';
import 'weui';
import { redirect } from 'react-router-dom'
import 'react-weui/build/packages/react-weui.css';
import axios from 'axios';
import GlobalTools from "./utils/GlobalTools";
import withRouter from "./withRouter"
import CityData from './utils/CityData';
import GDProductScreen from './GDProductScreen';
import XXProductScreen  from './XXProductScreen';
import TuiJian from './TuiJian';
import NNProductScreen from './NNProductScreen'
import LTProductScreen from './LTProductScreen'
import KHProductScreen from './KHProductScreen'


class ProductScreen extends React.Component {

    constructor(props) {
        super(props);
        let{u} = this.props.router.params;
        this.state = {
          product:{},
          isInited:false,
          isProductOff:false,
          isError:false,
          u: u
        };

        console.log("持久化U");
        //持久化U
        GlobalTools.writeU(u);
        
        //确保使用https
        GlobalTools.fixHttps();  

        this.iniProduct();

    };

    iniProduct(){
      var that = this;
      let {id}=that.props.router.params;
      var cache = GlobalTools.autoCache();
      axios.get( `/hopeapi/product/${id}${cache}` )
      .then(function (response) {
          console.log("request done");
          console.log(response);
         
          if(response.data.status){
            let product = response.data.data;
            that.setState({
              product: product,
              isInited:true
            })

          }else{
            //alert("此产品已下架");
            //that.props.history.push("/big?u="+u);
            that.setState({
              isProductOff:true,
              isInited:true
            })
          }
          
      })
      .catch(function(error){
         console.log(error);
         that.setState({
          isError:true,
          isInited:true
        })
      })
  }

    
    render() {
      let {isInited,product,u,isProductOff,isError} = this.state;

      if(!isInited)
        return (
           <>正在加载中，请稍后...</>
       );

       if(isProductOff){
        return (
              <Page  title="号码宝自助查询系统" subTitle="联通电信移动广电优惠套餐办理"  >
                  
                  <Article>
                    <h1>此产品已下架</h1>
                    <p>
                        来晚了一点点，错失一次机会。记得收藏我们，下次早点。让小编给你推荐最新的卡种吧！
                    </p>
                  </Article>
                  
                    <CellsTitle>卡种推荐</CellsTitle>
                    <Cells>
                      <TuiJian u={u}/>
                    </Cells>

            </Page>
          );

       }

       if(isError){
        return (
            <Page  title="号码宝自助查询系统" subTitle="联通电信移动广电优惠套餐办理"  >
                <Article>
                  <h1>出现异常</h1>
                  <p>
                      请稍后再试
                  </p>
                </Article>
          </Page>
        );

       }

      if(product.productMode==="gd2"){
        return(
          <GDProductScreen product={product}/>
        );

      }

      if(product.pageMode==="product" || product.pageMode==="oproduct"
        || product.pageMode==="ssproduct"){
        return(
          <XXProductScreen product={product}/>
      );

      }

      if(product.pageMode==="gdproduct"){

        return(
            <GDProductScreen product={product}/>
        );
      }


      if(product.pageMode==="ltproduct"){

      return(
        <LTProductScreen product={product}/>
      );
      }

      if(product.pageMode==="khproduct"){

      return(
        <KHProductScreen product={product}/>
      );
      }

      if(product.pageMode==="nnproduct"){

      return(
        <NNProductScreen product={product}/>
      );
      }

      return(
        <XXProductScreen product={product}/>
      );

    }

}


export default withRouter(ProductScreen);