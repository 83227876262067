import { Grids} from 'react-weui';
 


function HomeGrid(props) {
    //console.log("homegrid")
    //console.log(props)
    let {u} = props;

    let data = [{
        icon: <img src={'/images/search-outline.svg'}/>,
        label: '自助查单',
        href: `/cha/${u}`
    },
    
    {
        icon: <img src={'/images/arrow-right-arrow-left-solid.svg'}/>,
        label: '转让求购',
        href: `/trade/index/${u}`
    },
    {
        icon: <img src={'/images/infinity-solid.svg'}/>,
        label: '智能扫号',
        href: `https://hao.zuime.com/#/?u=${u}`
    },
    ];
    
    let data2 = [{
        icon: <img src={'/images/handshake-angle-solid.svg'}/>,
        label: '合作加盟',
        href:`/co/${u}`
    },
    
    {
        icon: <img src={'/images/mug-hot-solid.svg'}/>,
        label: '天使系统',
        href:`/tianshi/${u}`
    },
    {
        icon: <img src={'/images/cloud-solid.svg'}/>,
        label: '通信云下载',
        href:`/download/${u}`
    },
    ]


    return (
        <div>
            <Grids data={data} className="top-grid"/>
            <Grids data={data2} className="main-grid"/>
        </div>
    );
  }
  
  export default HomeGrid;