import '../App.css';
import './NewScreen.css';
import React from 'react';
import { Page,Button, Article,Msg, Footer, FooterLinks, FooterLink, FooterText, Panel, PanelBody, PanelHeader, ButtonArea} from 'react-weui';
import 'weui';
import 'react-weui/build/packages/react-weui.css';
import GlobalTools from "../utils/GlobalTools";
import withRouter from "../withRouter"

class IntroScreen extends React.Component {

    constructor(props) {
        super(props);
        console.log("构造器初始化");

        this.state = {
            qrcode:"https://cdn.zuime.com/hmb/hmbqr.jpg",
            u: props.router.params.u
        };

        //持久化U
        GlobalTools.writeU( props.router.params.u);
        //确保使用https
        GlobalTools.fixHttps(); 
    };

    
    componentWillMount(){

     }

    componentDidMount(){
      
    }
    
    render() {
        let {u} = this.state;
        return (
         <Page title="过户说明" subTitle="号码宝安全过户说明" infiniteLoader={false}
         ptr={false} >
    
          <Article>
                 <h1>过户说明</h1>
                 <p>
                 首选手拉手（两个人一起去营业厅办理过户），最安全！ 适合所有联通卡，联通支持异地过户！部份电信卡支持视频过户，只要两个人在一起，就能视频过户。
                 </p>

                 <section>
                    <p>其次，远程过户！部份电信卡支持视频异地过户，两个人不用在一起，也可以视频过户。不少地市支持联通远程过户，采用执照过户，不涉及收卡方证件资料，由出卡方提供过户授权资料，个人资料相对安全。</p>
                </section>

                <section>
                    <p>推荐寻找手拉手去营业厅当面过户的机会，安全为先，避免上当受骗。</p>
                </section>

            </Article>

            <ButtonArea>

                <Button type="default" onClick={() => this.props.router.navigate(`/trade/index/${u}`)} >返回</Button>
            </ButtonArea>

            <Panel>
                <PanelHeader className='hmb-new-trade-qrtxt'>号码宝公众号二维码</PanelHeader>
                <PanelBody>  <img className='hmb-new-trade-qr' src={this.state.qrcode}/></PanelBody>
            </Panel>

         </Page>
       );
    }

}


export default withRouter(IntroScreen);