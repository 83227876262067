import '../App.css';
import './NewScreen.css';
import React from 'react';
import { Page,Button, Article,Msg, Footer, FooterLinks, FooterLink, FooterText, Panel, PanelBody, PanelHeader, ButtonArea} from 'react-weui';
import 'weui';
import 'react-weui/build/packages/react-weui.css';
import GlobalTools from "../utils/GlobalTools";
import withRouter from "../withRouter"

class KefuScreen extends React.Component {

    constructor(props) {
        super(props);
        console.log("构造器初始化");

        this.state = {
            qrcode:"https://cdn.zuime.com/hmb/hmbqr.jpg",
            u: props.router.params.u
        };

        //持久化U
        GlobalTools.writeU( props.router.params.u);
        //确保使用https
        GlobalTools.fixHttps(); 
    };

    
    componentWillMount(){

     }

    componentDidMount(){
      
    }
    
    render() {
        let {u} = this.state;
        return (
         <Page title="在线搭桥" subTitle="号码宝在线搭桥服务" infiniteLoader={false}
         ptr={false} >
    
          <Article>
                 <h1>在线搭桥</h1>
                 <p>
                 微信客服 haomabaokefu
                 </p>
                 <p>
                 咨询工作时间 10:00 至 23:00
                 </p>

                 <p>
                 咨询电话 18232551029
                 </p>


            </Article>

            <ButtonArea>

                <Button type="default" onClick={() => this.props.router.navigate(`/trade/index/${u}`)} >返回</Button>
            </ButtonArea>

            <Panel>
                <PanelHeader className='hmb-new-trade-qrtxt'>号码宝公众号二维码</PanelHeader>
                <PanelBody>  <img className='hmb-new-trade-qr' src={this.state.qrcode}/></PanelBody>
            </Panel>

         </Page>
       );
    }

}


export default withRouter(KefuScreen);