import './NNProductScreen.css'
import React from 'react';
import {  
    Page,Flex,FlexItem,Badge,
    ButtonArea,
    Button,
    CellsTitle,
    CellsTips,
    Cell,
    CellHeader,
    CellBody,
    CellFooter,
    Form,
    FormCell,
    Icon,
    Input,
    Label,
    TextArea,
    Switch,
    Radio,
    Checkbox,
    Select,
    VCode,
    Agreement,
    Toptips} from 'react-weui';
import 'weui';
import { useParams } from 'react-router-dom'
import 'react-weui/build/packages/react-weui.css';
import axios from 'axios';
import GlobalTools from "./utils/GlobalTools";
import withRouter from "./withRouter"
import CityData from './utils/CityData';
import NNProductNumberList from './NNProductNumberList'


class XXProductScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          product:{},
          imageId1PreviewUrl:'',
          imageId1File:'',
          showId1:false,
          imageId0PreviewUrl:'',
          imageId0File:'',
          showId0:false,
          imageIdxPreviewUrl:'',
          imageIdxFile:'',
          showIdx:false,
          showBtn:true,
          provinceList:CityData[86],
          cityList:[],
          areaList:[],
          province:"",
          city:"",
          area:"",
          u: this.props.router.params.u,
          isInitNum:false,
          searchNum:"",
          isShowChooseNums:false,
          nums:[],
          chooseNum:"",
          isNumsLoading:false,

          isInitBelongNum:false,//收货地即归属地
          isShowBelongNums:false,//收货地即归属地号码是否展示
          isBelongNumsLoading:false,//收货地即归属地

        };

        console.log("持久化U");
        //持久化U
        GlobalTools.writeU(this.props.router.params.u);
        
        //确保使用https
        GlobalTools.fixHttps();  

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleProvinceChange = this.handleProvinceChange.bind(this);
        this.handleCityChange = this.handleCityChange.bind(this);
        this.handleAreaChange = this.handleAreaChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.searchNumber = this.searchNumber.bind(this);
        this.onSearchClick = this.onSearchClick.bind(this);
        this.searchBelongNumber = this.searchBelongNumber.bind(this);
        this.onSearchBelongClick = this.onSearchBelongClick.bind(this);
        this.onChangeNumBatchClick = this.onChangeNumBatchClick.bind(this);
        this.onChangeBelongNumBatchClick = this.onChangeBelongNumBatchClick.bind(this);

    };

    
    componentWillMount(){
 
     }

    componentDidMount(){
        console.log("产品页面-初始化产品");
        var that  = this ;
        //初始化产品
        if(this.props.product){
           this.setState({product:this.props.product});
        }else{
           this.iniProduct();
        }
        
        this.searchNumber();

        //build province data for weui
        let provinceListUI =  Object.entries( this.state.provinceList).map(x => {
            return  {value:x[0],label:x[1]}
        })

        console.log("provinceListUI");
        console.log(provinceListUI);
        provinceListUI.unshift({value:-1,label:"请选择省"})
        this.setState({
            provinceListUI:provinceListUI,
            cityListUI:[{value:-1,label:"请选择市"}],
            areaListUI:[{value:-1,label:"请选择区"}]
        });
    }

    iniProduct(){
        var that = this;
        let {u,id}=that.props.router.params
        console.log(that.props.router.params);
        this.setState({u:u});
        //持久化U
        GlobalTools.writeU(u);
        var cache = GlobalTools.autoCache();
        axios.get( `/hopeapi/product/${id}${cache}`)
        .then(function (response) {
            console.log("request done");
            console.log(response);
            if(response.data.status){
              that.setState({
                product: response.data.data
              })
    
                let debugdata = new FormData();
                debugdata.append('msg',"代理："+u+";客户访问："+response.data.data.productTitle);
      
                axios.post("/lkjapi/msg.php",debugdata)
                .then(function () {
                  
                
                });
    
              
            }else{
              alert("此产品已下架");
              //that.props.history.push("/big?u="+u);
            }
            
        })
        .catch(function(error){
           console.log(error)
        })
    }

    handleInputChange(e){
        console.log(e);
        console.log(this);
        e.preventDefault();
        console.log(e.target.name+":"+e.target.value);
        let o = {};
        o[e.target.name] = e.target.value;
        this.setState(o);
    }

    showWarn(warnInfo) {
      this.setState({showWarn: true,warnInfo:warnInfo});

      this.state.warnTimer = setTimeout(()=> {
          this.setState({showWarn: false,warnInfo:""});
      }, 2000);
  }
 
handleImageId1Change(e) {
    console.log("wa you want to upload");
    e.preventDefault();
      
    var reader = new FileReader();
    var file = e.target.files[0];
    
    reader.onloadend = () => {
      console.log('文件名为—',file);
      console.log('文件结果为—',reader.result);
      this.setState({
        imageId1File:file,
        imageId1PreviewUrl: reader.result,
        showId1:true
      });
    }
    
    reader.readAsDataURL(file) 
  
  }
  
  handleImageId0Change(e) {
    console.log("wa you want to upload");
    e.preventDefault();
      
    var reader = new FileReader();
    var file = e.target.files[0];
    
    reader.onloadend = () => {
      console.log('文件名为—',file);
      console.log('文件结果为—',reader.result);
      this.setState({
          imageId0File:file,
          imageId0PreviewUrl: reader.result,
          showId0:true
      });
    }
    
    reader.readAsDataURL(file) 
  
  }
  
  handleImageIdxChange(e) {
    console.log("wa you want to upload");
    e.preventDefault();
      
    var reader = new FileReader();
    var file = e.target.files[0];
    
    reader.onloadend = () => {
      console.log('文件名为—',file);
      console.log('文件结果为—',reader.result);
      this.setState({
         imageIdxFile:file,
         imageIdxPreviewUrl: reader.result,
         showIdx:true
      });
    }
    
    reader.readAsDataURL(file) 
  
  }
  
  uploadImage(api_url,file,orderCode,imageToken,nextJob){
    const formData = new FormData();
    console.log("开始上传照片------");
    // formData.append('file',value);
    formData.append('file', file)
    formData.append('token',imageToken);
    formData.append('orderCode',orderCode);
    let config = {
      method: 'post',
      headers:{'Content-Type': 'multipart/form-data'}
    }
    axios.post(api_url,formData,config).then((res) => {
      console.log("图片上传结果");
      console.log(res);
      if (res.status != 200) {
        alert("照片上传失败，请重试或者联系客服提交！");
        return false;
       }
      if (res.status === 200 && !res.data.status) {
   
        alert("照片上传失败，请重试或者联系客服提交！");
        return false;
      }
  
      //继续执行
      nextJob();
  
    }).catch((error) => {
      alert("照片上传失败，请重试或者联系客服提交！");
    })
  
  }
  
  uploadImageAll(uploadImageConfig,successJob,errorJob){
    const {api_url,imageId1File,imageId0File,imageIdxFile,orderCode,imageToken} = uploadImageConfig;
    const formData = new FormData();
    console.log("开始上传照片------");
    // formData.append('file',value);
    formData.append('id1', imageId1File);
    formData.append('id0', imageId0File);
    formData.append('idx', imageIdxFile);
    formData.append('token',imageToken);
    formData.append('orderCode',orderCode);
    let config = {
      method: 'post',
      headers:{'Content-Type': 'multipart/form-data'}
    }
    axios.post(api_url,formData,config).then((res) => {
      console.log("图片上传结果");
      console.log(res);
      if (res.status != 200) {
    
        errorJob();
        return false;
       }
      if (res.status === 200 && !res.data.status) {
   
      
        errorJob();
        return false;
      }
  
      //继续执行
      successJob();
  
    }).catch((error) => {
      errorJob();
   
    })
  
  }
  
  handleProvinceChange = (event)=>{
    const {name, value} = event.target;
    console.log("省发生变化");
    console.log(event);
     
    console.log(name);
    console.log(value);
    
    if(value===-1){
      this.setState({province:""});
      return;
    }
    this.setState({province:value,city:-1,area:-1});
   
    //反查省code
    let provinceItem = Object.entries( this.state.provinceList).filter(x => {
        console.log(x);
        return value === x[0] ;
    })
    if(provinceItem.length===0){
      this.setState({province:""});
      return;
    }
    console.log("找到省");
    console.log(provinceItem);
    console.log(CityData[provinceItem[0]]);
    this.setState({cityList:CityData[provinceItem[0][0]]});
    this.setState({provinceCode:provinceItem[0][0],provinceName:provinceItem[0][1]});

    //build weui所需数据
    let cityListUI =  Object.entries( CityData[provinceItem[0][0]] ).map(x => {
        return  {value:x[0],label:x[1]}
    })

    console.log("cityListUI");
    console.log(cityListUI);
    cityListUI.unshift({value:-1,label:"请选择市",selected:""})
    this.setState({cityListUI:cityListUI});
  }
  
  handleCityChange = (event)=>{
    const {name, value} = event.target;
    const {isInitNum,provinceName,provinceCode} = this.state;

    console.log(event);
    console.log(name);
    console.log(value);
    if(value===-1){
      this.setState({city:-1,area:-1});
      return;
    }
    this.setState({ city:value,area:-1});
    //反查市code
    let cityItem = Object.entries( this.state.cityList).filter(x => {
        return value === x[0] ;
    })
    if(cityItem.length===0){
      return;
    }
    console.log(cityItem);
    this.setState({areaList:CityData[cityItem[0][0]]});
    this.setState({cityCode:cityItem[0][0],cityName:cityItem[0][1]});

    //build weui所需数据
    let areaListUI =  Object.entries( CityData[cityItem[0][0]] ).map(x => {
    return  {value:x[0],label:x[1]}
    })

    console.log("areaListUI");
    console.log(areaListUI);
    areaListUI.unshift({value:-1,label:"请选择区"})
    this.setState({areaListUI:areaListUI});

    //尝试加载归属地可选号码 haha 全靠猜
    if(!isInitNum){
      this.setState({nums:[]});
      this.searchBelongNumber(provinceName,provinceCode,cityItem[0][1],cityItem[0][0]);
    }
    
  }
  
  handleAreaChange = (event)=>{
    const {name, value} = event.target;
    console.log(name);
    console.log(value);
    if(value==="请选择区"){
      //清空客户的选择
      this.setState({area:""});
      return;
    }
    this.setState({area:value});
   
    console.log(this.state.province);
    console.log(this.state.city);
    console.log(value);

     //反查市code
     let areaItem = Object.entries( this.state.areaList).filter(x => {
        return value === x[0] ;
    })
    if(areaItem.length===0){
      return;
    }
    console.log(areaItem);

    this.setState({areaCode:areaItem[0][0],areaName:areaItem[0][1]});

   
  }
  
  handleSubmit = (event)=>{
    event.preventDefault() //阻止表单提交
    const {number,idname,idnum,address,isInitNum,chooseNum,isInitBelongNum} = this.state;
    const {productPrice,productVCode,productTitle,productMode} = this.state.product;
    const {province,city,area,provinceName,cityName,areaName,
        provinceCode,cityCode,areaCode} = this.state;
    var that = this;
    
    console.log("可以提交数据了");

    if(( isInitNum || isInitBelongNum) && (chooseNum ==="" || !/\d{11}/gi.test(chooseNum))){
        this.showWarn("请选号");
        return;
      }
  
   
    if(number==="" || !/\d{11}/gi.test(number)){
          //alert("请输入手机号码！");
          that.showWarn("请输入手机号码");
          return;
      }
    
  
      if(idname==="" ){
        //alert("请输入姓名！");
        that.showWarn("请输入姓名");
        return;
    }
    
    if(idnum==="" ){
      //alert("请输入证件号码！");
      that.showWarn("请输入证件号码");
      return;
  }
    
  if(province===-1 || city===-1 || area===-1){
    //alert("请选择省市区");
    that.showWarn("请选择省市区");
    return;
  }
  
  if(address==="" ){
    //alert("请输入收货地址！");
    that.showWarn("请输入收货地址");
    return;
  }
  
  if(address.length<7 ){
    //alert("详情地址不能少于7个汉字");
    that.showWarn("详情地址不能少于7个汉字");
    return;
  }
  
    //最终使用地址
    let finalAddress = provinceName+cityName+areaName+address;
    //如果当前业务为需要传照片的，则检查照片是否上传
    if(productMode==="image"){
        console.log(this.state.imageId1File);
        console.log(this.state.imageId0File);
        console.log(this.state.imageIdxFile);
    
        if(!this.state.imageId1File || !this.state.imageId0File || !this.state.imageIdxFile){
            //alert("请上传照片！");
            that.showWarn("请上传照片");
            return;
        }
    
    }
  
      let orderdata = new FormData();
      orderdata.append('os',"10055.net"); //需要提取到全局参数中
      orderdata.append('idName',idname);
      orderdata.append('idNum',idnum);
      orderdata.append('phone',number);
      orderdata.append('address',address);
      orderdata.append('productVCode',productVCode);
      orderdata.append('productTitle',productTitle);
      orderdata.append('channel',this.state.u);
      orderdata.append('provinceCode',provinceCode);
      orderdata.append('provinceName',provinceName);
      orderdata.append('cityCode',cityCode);
      orderdata.append('cityName',cityName);
      orderdata.append('areaCode',areaCode);
      orderdata.append('areaName',areaName);
      orderdata.append("newPhone",chooseNum);
   
    that.setState({
        showBtn:false
    });

    axios.post("/hopeapi/xx/order/new",orderdata)
    .then(function (response) {
      console.log(response);
  
      if(response.status!==200){
        //alert("下单失败，稍后重试");
        that.showWarn("下单失败，稍后重试");
        that.setState({
          showBtn:true
         });
        return;
      }
    
      if(!response.data.status || response.data.status==="false"){
        alert(response.data.message);
        that.setState({
          showBtn:true
         });
        return;
      }

      that.setState({
        number:"",
        idname:'',
        idnum:"",
        address:"",
        province:-1,
        city:-1,
        area:-1
      });
      //如产品需要提交照片，则开始上传照片
      if(productMode==='image'){
          //"/hopeapi/biz/upload/id1"
          //  api_url,imageId1File,imageId0File,imageIdxFile,orderCode,imageToken
          var uploadImageConfig={
            api_url: "/hopeapi/biz/upload/ids",
            imageId1File: that.state.imageId1File,
            imageId0File: that.state.imageId0File,
            imageIdxFile: that.state.imageIdxFile,
            orderCode: response.data.data.orderCode,
            imageToken: response.data.data.imageToken
          }
          that.uploadImageAll(uploadImageConfig,function(){
            if(productPrice>0){
  
              that.setState({
                showBtn:true,
                showId1:false,
                showId0:false,
                showIdx:false
               });
               that.props.history.push("/pay/"+response.data.data.orderCode+"/"+response.data.data.imageToken);
            }
          },function(){
  
             //alert("照片上传失败，请重试或者联系客服提交！");
            that.setState({
              showBtn:true
             });
           
             that.props.history.push("/pay/"+response.data.data.orderCode+"/"+response.data.data.imageToken);
          });
  
      }else{
       
        if(productPrice>0){
          //alert("您下单的是付费产品，请联系页面底部平台客服，支付费用。");
          //跳到付款提示页面，拍出付款码/付款按扭
  
       
           that.props.router.navigate("/ok");
        }else{
          alert(response.data.message);
        }
      }
     
    })
    .finally(function(){
        that.setState({
            showBtn:true
           });
    });
  }
  
  searchNumber =()=>{
    console.log("正在加载选号数据");
    var that = this;
    let {searchNum,isInitNum} = this.state
    let {id} = that.props.router.params;
    let postData = new FormData();
    postData.append('vCode',id);
    postData.append('num',searchNum);
    that.setState({isNumsLoading:true,nums:[]});
    axios.post( "/hopeapi/xx/cha", postData)
    .then(function (response) {
        console.log("request done");
        console.log(response);
        //取到了号码说明，这个产品可以选号，后续提交订单则需要验证是否完成选号
        if (response.data && response.data.status) {
            if(!isInitNum){
              that.setState({isShowChooseNums:response.data.data.length>0,isInitNum:true});
            }
            console.log("选号数据已经填充");
            that.setState({nums:response.data.data});
        }else{
            //如果是加载完成之后，出现查不到号码的情况，则提醒一下
            if(isInitNum&&!response.data.status){
                that.showWarn("没搜到，请换条件");
            }
        }
    })
    .catch(function(error){
       console.log(error)
    })
    .finally(function(){
        that.setState({isNumsLoading:false});
    });
  }

  //收货地即归属地号码加载
  searchBelongNumber =(provinceName,provinceCode,cityName,cityCode)=>{
    console.log("正在加载选号数据");
    var that = this;
    let {searchNum,isInitBelongNum} = this.state
    let {id} = that.props.router.params;
    let postData = new FormData();
    postData.append('vCode',id);
    postData.append('num',searchNum);
    postData.append('belongProvinceName',provinceName);
    postData.append('belongProvinceCode',provinceCode);
    postData.append('belongCityName',cityName);
    postData.append('belongCityCode',cityCode);

    that.setState({isBelongNumsLoading:true,nums:[]});
    axios.post( "/hopeapi/xx/cha", postData)
    .then(function (response) {
        console.log("request done");
        console.log(response);
        //取到了号码说明，这个产品可以选号，后续提交订单则需要验证是否完成选号
        if (response.data && response.data.status) {
            if(!isInitBelongNum){
              that.setState({isShowBelongNums:response.data.data.length>0,isInitBelongNum:true});
            }
            console.log("选号数据已经填充");
            that.setState({nums:response.data.data});
        }else{
            //如果是加载完成之后，出现查不到号码的情况，则提醒一下
            if(isInitBelongNum&&!response.data.status){
                that.showWarn("没搜到，请换条件");
            }
        }
    })
    .catch(function(error){
       console.log(error)
    })
    .finally(function(){
        that.setState({isBelongNumsLoading:false});
    });
  }

  onNumChoose = (e)=>{
      console.log(e);
      this.setState({chooseNum:e.target.textContent})
  }

  onSearchClick =(e)=>{
    let{searchNum}=this.state;
    console.log("searchNum:"+searchNum);
    if(!/\d{1,11}/gi.test(searchNum)){
        //alert("请输入手机号码！");
        this.showWarn("仅搜数字");
        return;
    }  
    this.searchNumber();
  }

  onSearchBelongClick = (e) =>{
    let{searchNum}=this.state;
    console.log("searchNum:"+searchNum);
    if(!/\d{1,11}/gi.test(searchNum)){
        //alert("请输入手机号码！");
        this.showWarn("仅搜数字");
        return;
    }  
    let {provinceName,cityName,provinceCode,cityCode} = this.state
    this.searchBelongNumber(provinceName,provinceCode,cityName,cityCode);
  }

  onChangeNumBatchClick=(e)=>{
    let{searchNum}=this.state;
    console.log("searchNum:"+searchNum);
    if(searchNum===""){
        this.searchNumber();
        return;
    }
    if(!/\d{1,11}/gi.test(searchNum)){
        //alert("请输入手机号码！");
        this.showWarn("仅搜数字");
        return;
    }  
    this.searchNumber();
  }

  onChangeBelongNumBatchClick = (e)=>{
    let{searchNum,provinceName,cityName,provinceCode,cityCode}=this.state;
    console.log("searchNum:"+searchNum);
    if(searchNum===""){
      this.searchBelongNumber(provinceName,provinceCode,cityName,cityCode);
        return;
    }
    if(!/\d{1,11}/gi.test(searchNum)){
        //alert("请输入手机号码！");
        this.showWarn("仅搜数字");
        return;
    }  

    this.searchBelongNumber(provinceName,provinceCode,cityName,cityCode);

  }

    render() {
        var that = this;
        let{nums,chooseNum,isShowChooseNums,isNumsLoading,isBelongNumsLoading,isShowBelongNums}=this.state;
        let {onNumChoose,onSearchClick,onSearchBelongClick,handleInputChange,onChangeNumBatchClick,onChangeBelongNumBatchClick} = this;

        return (
            <Page className="input" title="号码宝特色卡种" subTitle="联通电信移动广电优惠套餐办理" infiniteLoader={false}
             ptr={false}  >       
            <img src={this.state.product.productCoverUrl} style={{width:"100%"}}/>
            <div className='hmbProductTitle'>{this.state.product.productTitle}</div>

            <NNProductNumberList 
            nums={nums} 
            chooseNum={chooseNum} 
            isShowChooseNums={isShowChooseNums} 
            onSearchClick = {onSearchClick}
            handleInputChange ={handleInputChange}
            onNumChoose={onNumChoose}
            isNumsLoading={isNumsLoading}
            onChangeNumBatchClick = {onChangeNumBatchClick}

            ></NNProductNumberList>

            <CellsTitle>实名信息</CellsTitle>
            <Form>
                    <FormCell>
                        <CellHeader>
                            <Label>姓名</Label>
                        </CellHeader>
                        <CellBody>
                            <Input type="text" placeholder="输入姓名" onChange={handleInputChange}  name="idname"/>
                        </CellBody>
                    </FormCell>

                    <FormCell>
                        <CellHeader>
                            <Label>身份证号</Label>
                        </CellHeader>
                        <CellBody>
                            <Input type="text" placeholder="输入身份证号"  onChange={handleInputChange}   name="idnum" />
                        </CellBody>
                    </FormCell>

                    <FormCell>
                        <CellHeader>
                            <Label>联系号码</Label>
                        </CellHeader>
                        <CellBody>
                            <Input type="text" placeholder="输入联系号码"  onChange={handleInputChange}   name="number" />
                        </CellBody>
                    </FormCell>

                 
            </Form>

            <CellsTitle>收货地址</CellsTitle>
            <Form>
                 <FormCell select selectPos="after">
                    <CellHeader>
                        <Label>省</Label>
                    </CellHeader>
                    <CellBody>
                        <Select data={this.state.provinceListUI}  onChange={this.handleProvinceChange} />
                    </CellBody>
                </FormCell>

                <FormCell select selectPos="after">
                    <CellHeader>
                        <Label>市</Label>
                    </CellHeader>
                    <CellBody>
                        <Select data={this.state.cityListUI} value={this.state.city}  onChange={this.handleCityChange} />
                    </CellBody>
                </FormCell>

                <FormCell select selectPos="after">
                    <CellHeader>
                        <Label>区</Label>
                    </CellHeader>
                    <CellBody>
                        <Select data={this.state.areaListUI}  onChange={this.handleAreaChange} />
                    </CellBody>
                </FormCell>

                <FormCell>
                        <CellHeader>
                            <Label>详情地址</Label>
                        </CellHeader>
                        <CellBody>
                            <Input type="text" placeholder="至少7个汉字 勿重复省市区" onChange={handleInputChange}   name="address" />
                        </CellBody>
                    </FormCell>

            </Form>


            <NNProductNumberList 
            nums={nums} 
            chooseNum={chooseNum} 
            isShowChooseNums={isShowBelongNums} 
            onSearchClick = {onSearchBelongClick}
            handleInputChange ={handleInputChange}
            onNumChoose={onNumChoose}
            isNumsLoading={isBelongNumsLoading}
            onChangeNumBatchClick = {onChangeBelongNumBatchClick}
            ></NNProductNumberList>
            
            <ButtonArea>
                     <Button onClick={this.handleSubmit}  style={{ display: this.state.showBtn?'block':'none' }}>
                         {this.state.product.productPrice}元{this.state.product.productBtn}
                     </Button>


                     <Button style={{ display: !this.state.showBtn?'block':'none' }}>
                        正在提交，请稍后
                    </Button>


            </ButtonArea>
            
            <img src={this.state.product.productDetailUrl} style={{width:"100%",marginTop: '0.5em'}}/>
            <img src="/warnnotice.jpg" style={{width:"100%"}}/>

            <Toptips type="warn" show={this.state.showWarn}> {this.state.warnInfo} </Toptips>
         </Page>
       );
    }

}


export default withRouter(XXProductScreen);