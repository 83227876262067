
import logo from './logo.svg';
import './App.css';
import './HomeScreen.css';
import React from 'react';
import { Page,Flex,FlexItem,
    ButtonArea,
    Button,
    CellsTitle,
    CellsTips,
    Cell,
    CellHeader,
    CellBody,
    CellFooter,
    Form,
    FormCell,
    Icon,
    Input,
    Cells,
    Label,
    TextArea,
    Switch,
    Radio,
    Checkbox,
    Select,
    VCode,
    Agreement,
    Toptips,
    Preview, PreviewHeader, PreviewFooter, PreviewBody, PreviewItem, PreviewButton } from 'react-weui';
import 'weui';
import 'react-weui/build/packages/react-weui.css';
import HomeGrid from './HomeGrid';
import axios from 'axios';
import ProductList from "./ProductList";
import GlobalTools from "./utils/GlobalTools";

class TuiJian extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
           products:[]
          };
    };

    componentDidMount(){
        //初始化产品
        this.initProductList();

    }

   initProductList(){
        console.log("初始化产品列表");
        var that = this;
        axios.get( '/hopeapi/tui/product/list' )
        .then( function (response) {
            console.log("request done");
            //console.log(response);
            if (response.data.status && response.data.data && response.data.data.length > 0) {
                const dataArry = response.data.data || []
                that.setState({products:dataArry});
            }
        })
      
    }

    

    render() {

       let {u} = this.props;
 
      return  this.state.products.map(product =>
       
          
                <Cell key={product.productVCode} href={`/ka/${product.productVCode}/${u}`} access>
                    <CellBody>
                        {product.productTitle}
                    </CellBody>
                    <CellFooter/>
                </Cell>
                
   
          );

    }

}


export default TuiJian;