
import logo from './logo.svg';
import './App.css';
import React from 'react';
import { Button,Page,Grids,Flex,FlexItem,   Footer,
    FooterText,
    FooterLinks,
    FooterLink,NavLink,InfiniteLoader} from 'react-weui';
import 'weui';
import 'react-weui/build/packages/react-weui.css';
import HomeGrid from './HomeGrid';
import axios from 'axios';
import ProductList from "./ProductList";
import GlobalTools from "./utils/GlobalTools";

class NotFoundScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          leftProducts:[],
          rightProducts:[],
          leftHeight:0,
          rightHeight:0,
          page:1
        };

        console.log("构造器初始化");
    };

    
    componentWillMount(){

     }

    componentDidMount(){
        console.log("智能追踪推广员");
        var that  = this ;
        GlobalTools.autoU(function(context){
            console.log("得到U:"+context.u);
            that.setState({u:context.u});
        });

        //确保使用https
        GlobalTools.fixHttps(); 
    }

  
    
    render() {
        var that = this;
        return (
            <Page   title="号码宝" subTitle="联通电信移动广电优惠套餐办理" infiniteLoader={false}
             ptr={false}  >
            
             <Flex >
                 <FlexItem>
                       未找到页面 
                 </FlexItem>
          
             </Flex>


             <Footer>
                <FooterLinks>
                    <FooterLink href="https://www.10055.net">号码宝</FooterLink>
                    <FooterLink href="https://beian.miit.gov.cn/">京ICP备15052719号-7</FooterLink>
                </FooterLinks>
                <FooterText>Copyright &copy; 2015-2029 www.10055.net</FooterText>
            </Footer>
           
         </Page>
       );
    }

}


export default NotFoundScreen;