
import logo from './logo.svg';
import './App.css';
import './HomeScreen.css';
import React from 'react';
import { Page,Flex,FlexItem,
    ButtonArea,
    Button,
    CellsTitle,
    CellsTips,
    Cell,
    CellHeader,
    CellBody,
    CellFooter,
    Form,
    FormCell,
    Icon,
    Input,
    Cells,
    Label,
    TextArea,
    Switch,
    Radio,
    Checkbox,
    Select,
    VCode,
    Agreement,
    Toptips,
    Preview, PreviewHeader, PreviewFooter, PreviewBody, PreviewItem, PreviewButton,Footer,FooterText,
    FooterLinks,
    FooterLink,
} from 'react-weui';
import 'weui';
import 'react-weui/build/packages/react-weui.css';
import HomeGrid from './HomeGrid';
import axios from 'axios';
import ProductList from "./ProductList";
import GlobalTools from "./utils/GlobalTools";
import ChaResult from "./ChaResult"
import TuiJian from "./TuiJian"
import withRouter from "./withRouter"

class LoginScreen extends React.Component {

    constructor(props) {
        super(props);
      
        this.state = {
            showBtn:true,
            numbers:[],
            code:""

        };

        console.log("持久化U");
        //持久化U
        //GlobalTools.writeU(props.router.params.u);
        //确保使用https
        GlobalTools.fixHttps(); 

        this.handleInputChange = this.handleInputChange.bind(this);
        this.onOrderCodeClick = this.onOrderCodeClick.bind(this);
        this.onExpressClick = this.onExpressClick.bind(this);
        console.log("构造器初始化");
    };

    
    componentWillMount(){

     }

    componentDidMount(){
  
    }

    handleInputChange(e){
        let o = {};
        o[e.target.name] = e.target.value;
        this.setState(o);
    }

    showWarn(warnInfo) {
      this.setState({showWarn: true,warnInfo:warnInfo});

      this.state.warnTimer = setTimeout(()=> {
          this.setState({showWarn: false,warnInfo:""});
      }, 2000);
    }

    handleSubmit = (event)=>{
        event.preventDefault() //阻止表单提交
        const {code} = this.state
        var that = this;
        console.log("可以提交数据了");
       
 
        
        if(code==="" ){
          //alert("请输入证件号码！");
          this.showWarn("请输入邀请码");
          return;
         }
        
       
        let orderdata = new FormData();
        orderdata.append('u',code);
        axios.post("/hopeapi/ka/check",orderdata)
        .then(function (response) {
          console.log(response);
          if(response.data.status){
            //that.setState({numbers:response.data.data});
            window.location = `/ting/${code}`
          }else{
            that.showWarn(response.data.message);
          }
        });
    }

    onOrderCodeClick(e){
        console.log(e);
        navigator.clipboard.writeText(e)
        this.showWarn("复制成功");

    }

    onExpressClick(e){
        console.log(e);
        navigator.clipboard.writeText(e)
        this.showWarn("复制成功");
    }

    render() {
        var that = this;
        let {u} = this.state;
 
        return (
            <Page  title="通信云" subTitle="联通电信移动广电优惠套餐办理"  >
              
              <CellsTitle>登陆通信云</CellsTitle>
              <Form>
                    <FormCell>
                        <CellHeader>
                            <Label>邀请码</Label>
                        </CellHeader>
                        <CellBody>
                            <Input type="text" placeholder="输入邀请码"  onChange={this.handleInputChange}   name="code" />
                        </CellBody>
                    </FormCell>
            </Form>
           
            <ButtonArea>
                     <Button onClick={this.handleSubmit} style={{ display: this.state.showBtn?'block':'none' }} >
                         进入
                     </Button>


                     <Button style={{ display: !this.state.showBtn?'block':'none' }}>
                        正在查询，请稍后
                    </Button>


            </ButtonArea>


            <Footer className="weui-footer-login">
                <FooterLinks>
                    <FooterLink href="https://www.10087.net">通信云 10087.net</FooterLink>
                    <FooterLink href="https://beian.miit.gov.cn/">京ICP备15052719号-12</FooterLink>
                </FooterLinks>
                <FooterText>Copyright &copy; 2015-2099 www.10087.net</FooterText>
            </Footer>
         
            <Toptips type="warn" show={this.state.showWarn}> {this.state.warnInfo} </Toptips>
           
         </Page>
       );
    }

}


export default withRouter(LoginScreen);