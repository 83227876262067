
import logo from './logo.svg';
import './App.css';
import './HomeScreen.css';
import React from 'react';
import { Page,Flex,FlexItem,
    ButtonArea,
    Button,
    CellsTitle,
    CellsTips,
    Cell,
    CellHeader,
    CellBody,
    CellFooter,
    Form,
    FormCell,
    Icon,
    Input,
    Cells,
    Label,
    TextArea,
    Switch,
    Radio,
    Checkbox,
    Select,
    VCode,
    Agreement,
    Toptips,
    Preview, PreviewHeader, PreviewFooter, PreviewBody, PreviewItem, PreviewButton } from 'react-weui';
import 'weui';
import 'react-weui/build/packages/react-weui.css';
import HomeGrid from './HomeGrid';
import axios from 'axios';
import ProductList from "./ProductList";
import GlobalTools from "./utils/GlobalTools";

class ChaResult extends React.Component {

    constructor(props) {
        super(props);

    };

    
  onOrderCodeClick = (e)=>{
    console.log("onOrderCodeClick");
    console.log(e);
    this.props.onOrderCodeClick(e);
  }


  onExpressClick = (e)=>{
    //console.log("city被点击");
    //console.log(city);
    //console.log(this.props.cityCode);
    //console.log(this.props.cityName);
    console.log("onExpressClick");
    console.log(e);
    this.props.onExpressClick(e);
  }

    render() {

      let {onOrderCodeClick,onExpressClick}= this;
 
      return  this.props.orders.map(order =>
            <div key={order.orderCode}>
                <Preview > 
                <PreviewHeader>
                    <PreviewItem label="订单号" value={"HMB"+order.orderCode} />
                </PreviewHeader>
                <PreviewBody>
                    <PreviewItem label="卡种" value={order.productTitle} />
                    <PreviewItem label="状态" value={order.orderStatus} />
                    <PreviewItem label="快递" value={order.express} />
                    <PreviewItem label="快递单号" value={order.expressNum} />
                </PreviewBody>
                <PreviewFooter>
                    <PreviewButton   onClick={()=>onExpressClick("快递："+order.express+",单号："+order.expressNum)} >复制快递</PreviewButton>
                    <PreviewButton   onClick={()=>onOrderCodeClick("HMB"+order.orderCode)} primary>复制单号</PreviewButton>
                </PreviewFooter>
            </Preview>
            <br/>
            </div>
          );

    }

}


export default ChaResult;