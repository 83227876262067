import logo from './logo.svg';
import './App.css';
import React from 'react';
import { Button,Page,Grids,Flex,FlexItem,   Footer,
    FooterText,
    FooterLinks,
    FooterLink,NavLink,InfiniteLoader,Article} from 'react-weui';
import 'weui';
import 'react-weui/build/packages/react-weui.css';
import HomeGrid from './HomeGrid';
import axios from 'axios';
import ProductList from "./ProductList";
import GlobalTools from "./utils/GlobalTools";
import withRouter from "./withRouter"

class TianshiScreen extends React.Component {

    constructor(props) {
        super(props);
        console.log("构造器初始化");

        this.state = {
            qrcode:"https://cdn.zuime.com/hmb/gdkefuqr.jpg",
            u: props.router.params.u
        };

        console.log("持久化U");
        //持久化U
        GlobalTools.writeU(props.router.params.u);
        //确保使用https
        GlobalTools.fixHttps(); 

       
    };

    
    componentWillMount(){

     }

    componentDidMount(){
    
    }

  
    
    render() {
        var that = this;
        return (
            <Page title="号码宝天使系统" subTitle="老带新，享受邀请福利！" transition={true} infiniteLoader={false} ptr={false}>
            
            <Article>
                 <h1>天使系统</h1>
                 <p>
                 号码宝天使系统是老带新邀请系统，老用户邀请新用户，可以获得邀请福利。
                 </p>
 
                <section>
                    <h2>申请开通权限，请联系</h2>
                    <p>商拓微信：13682586395</p>
                    <p>商拓电话：15733892563</p>
                    <p>上午不接电话 也是备用微信 </p>
                </section>

                <p>
                        <img  src={this.state.qrcode} alt="号码宝"/>
                </p>
            </Article>
         </Page>
       );
    }

}


export default withRouter(TianshiScreen);