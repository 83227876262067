import logo from '../logo.svg';
import '../App.css';
import React from 'react';
import { Button,Page,Grids,Flex,FlexItem,   Footer,
    FooterText,
    FooterLinks,
    FooterLink,NavLink,InfiniteLoader,Article,
    Panel,
    PanelHeader,
    PanelBody,
    PanelFooter,
    MediaBox,
    MediaBoxHeader,
    MediaBoxBody,
    MediaBoxTitle,
    MediaBoxDescription,
    MediaBoxInfo,
    MediaBoxInfoMeta,
    Cells,
    Cell,
    CellHeader,
    CellBody,
    CellFooter} from 'react-weui';
import 'weui';
import 'react-weui/build/packages/react-weui.css';
import axios from 'axios';
import GlobalTools from "../utils/GlobalTools";
import TradeGrid from "./TradeGrid"
import TradeList from './TradeList';
import withRouter from "../withRouter"

const appMsgIcon = <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAB4CAMAAAAOusbgAAAAeFBMVEUAwAD///+U5ZTc9twOww7G8MYwzDCH4YcfyR9x23Hw+/DY9dhm2WZG0kbT9NP0/PTL8sux7LFe115T1VM+zz7i+OIXxhes6qxr2mvA8MCe6J6M4oz6/frr+us5zjn2/fa67rqB4IF13XWn6ad83nxa1loqyirn+eccHxx4AAAC/klEQVRo3u2W2ZKiQBBF8wpCNSCyLwri7v//4bRIFVXoTBBB+DAReV5sG6lTXDITiGEYhmEYhmEYhmEYhmEY5v9i5fsZGRx9PyGDne8f6K9cfd+mKXe1yNG/0CcqYE86AkBMBh66f20deBc7wA/1WFiTwvSEpBMA2JJOBsSLxe/4QEEaJRrASP8EVF8Q74GbmevKg0saa0B8QbwBdjRyADYxIhqxAZ++IKYtciPXLQVG+imw+oo4Bu56rjEJ4GYsvPmKOAB+xlz7L5aevqUXuePWVhvWJ4eWiwUQ67mK51qPj4dFDMlRLBZTqF3SDvmr4BwtkECu5gHWPkmDfQh02WLxXuvbvC8ku8F57GsI5e0CmUwLz1kq3kD17R1In5816rGvQ5VMk5FEtIiWislTffuDpl/k/PzscdQsv8r9qWq4LRWX6tQYtTxvI3XyrwdyQxChXioOngH3dLgOFjk0all56XRi/wDFQrGQU3Os5t0wJu1GNtNKHdPqYaGYQuRDfbfDf26AGLYSyGS3ZAK4S8XuoAlxGSdYMKwqZKM9XJMtyqXi7HX/CiAZS6d8bSVUz5J36mEMFDTlAFQzxOT1dzLRljjB6+++ejFqka+mXIe6F59mw22OuOw1F4T6lg/9VjL1rLDoI9Xzl1MSYDNHnPQnt3D1EE7PrXjye/3pVpr1Z45hMUdcACc5NVQI0bOdS1WA0wuz73e7/5TNqBPhQXPEFGJNV2zNqWI7QKBd2Gn6AiBko02zuAOXeWIXjV0jNqdKegaE/kJQ6Bfs4aju04lMLkA2T5wBSYPKDGF3RKhFYEa6A1L1LG2yacmsaZ6YPOSAMKNsO+N5dNTfkc5Aqe26uxHpx7ZirvgCwJpWq/lmX1hA7LyabQ34tt5RiJKXSwQ+0KU0V5xg+hZrd4Bn1n4EID+WkQdgLfRNtvil9SPfwy+WQ7PFBWQz6dGWZBLkeJFXZGCfLUjCgGgqXo5TuSu3cugdcTv/HjqnBTEMwzAMwzAMwzAMwzAMw/zf/AFbXiOA6frlMAAAAABJRU5ErkJggg==" />

class IndexScreen extends React.Component {

    constructor(props) {
        super(props);
        console.log("构造器初始化");
        this.state = { 
            page:1,
            trades:[],
            u:this.props.router.params.u
        }

        console.log("持久化U");
        //持久化U
        GlobalTools.writeU(this.props.router.params.u);
        //确保使用https
        GlobalTools.fixHttps(); 

        this.onLoadMore = this.onLoadMore.bind(this);
    };

    
    componentWillMount(){

     }

    componentDidMount(){

        this.initTradeList();
    }


    initTradeList(){
        console.log("初始化转让列表");
        var that = this;
        axios.get( "/hopeapi/bus/list" )
        .then( function (response) {
            console.log("request done");
            //console.log(response);
            if (response.data.status && response.data.data && response.data.data.length > 0) {
                const dataArry = response.data.data || []
                that.setState({trades:dataArry});  
            }
        })
      
    }

    onLoadMore = (resolve, finish) => {
        console.log("加载更多列表");
        var page = this.state.page+1;
        var that  = this;

        axios.get( `/hopeapi/bus/list/${page}` )
        .then(async function (response) {
            console.log("request done");
            console.log(response);
            if (response.data.status && response.data.data ) {
                const dataArry = response.data.data || []
                if(response.data.data.length==0){
                    finish();
                }
                
                that.setState({trades:[...that.state.trades,...dataArry]});
              
                if(response.data.data.length>0 && response.data.data.length<100){
                    finish();
                }
                if(response.data.data.length>=100){ 
                    resolve();
                }
                
            }
        })

    };
  
    
    render() {
        let {u,trades} = this.state;
        return (
         <Page title="号码宝神卡转让平台" subTitle="联通电信移动广电优惠套餐办理" infiniteLoader={true}
         onLoadMore={(resolve, finish) => this.onLoadMore(resolve, finish)} ptr={false} >
           <TradeGrid u={u}></TradeGrid>
           <Panel>
                    <PanelBody>
                       <TradeList trades={trades} u={u}></TradeList>
                    </PanelBody>
                </Panel>
         </Page>
       );
    }

}


export default withRouter(IndexScreen);