
import logo from './logo.svg';
import './App.css';
import './HomeScreen.css';
import React from 'react';
import { Page,Flex,FlexItem,
    ButtonArea,
    Button,
    CellsTitle,
    CellsTips,
    Cell,
    CellHeader,
    CellBody,
    CellFooter,
    Form,
    FormCell,
    Icon,
    Input,
    Cells,
    Label,
    TextArea,
    Switch,
    Radio,
    Checkbox,
    Select,
    VCode,
    Agreement,
    Toptips,
    Preview, PreviewHeader, PreviewFooter, PreviewBody, PreviewItem, PreviewButton } from 'react-weui';
import 'weui';
import 'react-weui/build/packages/react-weui.css';
import HomeGrid from './HomeGrid';
import axios from 'axios';
import ProductList from "./ProductList";
import GlobalTools from "./utils/GlobalTools";
import ChaResult from "./ChaResult"
import TuiJian from "./TuiJian"
import withRouter from "./withRouter"

class ChaScreen extends React.Component {

    constructor(props) {
        super(props);
      
        this.state = {
            showBtn:true,
            numbers:[],
            u: props.router.params.u
        };

        console.log("持久化U");
        //持久化U
        GlobalTools.writeU(props.router.params.u);
        //确保使用https
        GlobalTools.fixHttps(); 

        this.handleInputChange = this.handleInputChange.bind(this);
        this.onOrderCodeClick = this.onOrderCodeClick.bind(this);
        this.onExpressClick = this.onExpressClick.bind(this);
        console.log("构造器初始化");
    };

    
    componentWillMount(){

     }

    componentDidMount(){
  
    }

    handleInputChange(e){
        let o = {};
        o[e.target.name] = e.target.value;
        this.setState(o);
    }

    showWarn(warnInfo) {
      this.setState({showWarn: true,warnInfo:warnInfo});

      this.state.warnTimer = setTimeout(()=> {
          this.setState({showWarn: false,warnInfo:""});
      }, 2000);
    }

    handleSubmit = (event)=>{
        event.preventDefault() //阻止表单提交
        const {number,idnum} = this.state
        var that = this;
        console.log("可以提交数据了");
       
        if(number==="" || !/\d{11}/gi.test(number)){
              //alert("请输入手机号码！");
              this.showWarn("请输入手机号码！");
              return;
          }
        
        if(idnum==="" ){
          //alert("请输入证件号码！");
          this.showWarn("请输入证件号码！");
          return;
         }
        
       
          let orderdata = new FormData();
          orderdata.append('idNum',idnum);
          orderdata.append('phone',number);
       
        axios.post("/hopeapi/order/cha",orderdata)
        .then(function (response) {
          console.log(response);
          if(response.data.status){
            that.setState({numbers:response.data.data});
          }else{
            //alert(response.data.message);
            that.showWarn(response.data.message);
          }
         
      
      
        });
    }

    onOrderCodeClick(e){
        console.log(e);
        navigator.clipboard.writeText(e)
        this.showWarn("复制成功");

    }

    onExpressClick(e){
        console.log(e);
        navigator.clipboard.writeText(e)
        this.showWarn("复制成功");
    }

    render() {
        var that = this;
        let {u} = this.state;
 
        return (
            <Page  title="号码宝自助查询系统" subTitle="联通电信移动广电优惠套餐办理"  >
              
              <CellsTitle>自助查单</CellsTitle>
              <Form>
                    <FormCell>
                        <CellHeader>
                            <Label>身份证号</Label>
                        </CellHeader>
                        <CellBody>
                            <Input type="text" placeholder="输入身份证号"  onChange={this.handleInputChange}   name="idnum" />
                        </CellBody>
                    </FormCell>

                    <FormCell>
                        <CellHeader>
                            <Label>联系号码</Label>
                        </CellHeader>
                        <CellBody>
                            <Input type="text" placeholder="输入联系号码"  onChange={this.handleInputChange}   name="number" />
                        </CellBody>
                    </FormCell>

                 
            </Form>
           
            <ButtonArea>
                     <Button onClick={this.handleSubmit} style={{ display: this.state.showBtn?'block':'none' }} >
                         查询
                     </Button>


                     <Button style={{ display: !this.state.showBtn?'block':'none' }}>
                        正在查询，请稍后
                    </Button>


            </ButtonArea>

            <ChaResult  orders={this.state.numbers} onOrderCodeClick={this.onOrderCodeClick} onExpressClick={this.onExpressClick} />
    
                <CellsTitle>卡种推荐</CellsTitle>
                <Cells>
                   <TuiJian u={u}/>
                </Cells>
            
         
            <Toptips type="warn" show={this.state.showWarn}> {this.state.warnInfo} </Toptips>
           
         </Page>
       );
    }

}


export default withRouter(ChaScreen);