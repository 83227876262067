import React from 'react';
import {  
    Page,Msg} from 'react-weui';
import 'weui';
import { useParams } from 'react-router-dom'
import 'react-weui/build/packages/react-weui.css';
import axios from 'axios';
import GlobalTools from "./utils/GlobalTools";
import withRouter from "./withRouter"
import CityData from './utils/CityData';

const ProductOKScreen = (props) => {
    return (
    <Page className="msg_success">
        <Msg
            type="success"
            title="下单成功"
            description="预计1到3天处理完，可自助查单。如超过3天，请联系客服排查原因。"
            buttons={[]}
           
        />
    </Page>
    )
}

export default ProductOKScreen;