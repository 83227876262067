import { Grids} from 'react-weui';
 


function TradeGrid(props) {

    let {u} = props;

    const data = [{
        icon: <img src={'/images/new_write_edit_icon.svg'}/>,
        label: '登记',
        href: `/trade/new/${u}`
    },
    
    {
        icon: <img src={'/images/bus.svg'}/>,
        label: '我的',
        href: `/trade/my/${u}`
    },
    {
        icon: <img src={'/images/customer_head_phones_service_icon.svg'}/>,
        label: '在线搭桥',
        href: `/trade/kefu/${u}`
    },
    ];
    
    const data2 = [{
        icon: <img src={'/images/subscribe.svg'}/>,
        label: '通知订阅',
        href: `/trade/subscribe/${u}`
    },
    
    {
        icon: <img src={'/images/message_icon.svg'}/>,
        label: '消息',
        href: `/trade/msg/${u}`  
    },
    {
        icon: <img src={'/images/arrow-right-arrow-left-solid.svg'}/>,
        label: '过户说明',
        href: `/trade/intro/${u}`
    },
    ]


    return (
        <div>
            <Grids data={data} className="trade-grid"/>
            <Grids data={data2} className="trade-grid"/>
        </div>
    );
  }
  
  export default TradeGrid;