import '../App.css';
import './NewScreen.css';
import React from 'react';
import { Page, Msg, Footer, FooterLinks, FooterLink, FooterText, Panel, PanelBody, PanelHeader} from 'react-weui';
import 'weui';
import 'react-weui/build/packages/react-weui.css';
import GlobalTools from "../utils/GlobalTools";
import withRouter from "../withRouter"

class MsgScreen extends React.Component {

    constructor(props) {
        super(props);
        console.log("构造器初始化");

        this.state = {
            qrcode:"https://cdn.zuime.com/hmb/hmbqr.jpg",
            u: props.router.params.u
        };

        //持久化U
        GlobalTools.writeU( props.router.params.u);
        //确保使用https
        GlobalTools.fixHttps(); 
    };

    
    componentWillMount(){

     }

    componentDidMount(){
      
    }
    
    render() {
        let {u} = this.state;
        return (
         <Page title="消息中心" subTitle="号码宝转让平台消息中心" infiniteLoader={false}
         ptr={false} >
    
            <Msg
                type="info"
                title="消息中心"
                description="消息中心暂未开通，请联系客服搭桥"
                buttons={[  {
                    type: 'default',
                    label: '返回',
                    onClick: () => this.props.router.navigate(`/trade/index/${u}`)
                }]}
    
            />

            <Panel>
                <PanelHeader className='hmb-new-trade-qrtxt'>号码宝公众号二维码</PanelHeader>
                <PanelBody>  <img className='hmb-new-trade-qr' src={this.state.qrcode}/></PanelBody>
            
            </Panel>

         </Page>
       );
    }

}


export default withRouter(MsgScreen);