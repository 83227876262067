import logo from './logo.svg';
import './App.css';
import React from 'react';
import { Button,Page,Grids,Flex,FlexItem,   Footer,
    FooterText,
    FooterLinks,
    FooterLink,NavLink,InfiniteLoader,Article, ButtonArea} from 'react-weui';
import 'weui';
import 'react-weui/build/packages/react-weui.css';
import HomeGrid from './HomeGrid';
import axios from 'axios';
import ProductList from "./ProductList";
import GlobalTools from "./utils/GlobalTools";
import withRouter from "./withRouter"

class DownloadScreen extends React.Component {

    constructor(props) {
        super(props);
        console.log("构造器初始化");

        this.state = {
            qrcode:"https://cdn.zuime.com/hmb/gdkefuqr.jpg",
            u: props.router.params.u
        };

        console.log("持久化U");
        //持久化U
        GlobalTools.writeU(props.router.params.u);
        //确保使用https
        GlobalTools.fixHttps(); 

       
    };

    
    componentWillMount(){

     }

    componentDidMount(){
    
    }

  
    
    render() {
        var that = this;
        return (
            <Page title="通信云APP下载" subTitle="联通电信移动广电优惠套餐办理" transition={true} infiniteLoader={false} ptr={false}>
            
            <Article>
                 <h1>通信云APP下载</h1>
                 <p>
                    通信云提供优惠套餐办理，安装APP，办卡不迷路。
                 </p>
                 <img className='txy_show' src='/images/txy_show.png'/>
                 <p>
                    通信云提供运营商优惠套餐办理，提供在线选号、大流量、大语音、靓号、流量卡、校园卡、宽带卡、上网设备、官宣双不限卡、绝版卡、物联卡、CPE、随身Wifi等。联通电信广电移动优惠套餐；异地选号办卡、3A4A、生日号定制等靓号；5G双不限宽带卡；无限卡转让；智能候车；电霸卡、嗨钉卡、银钉卡、大王卡、骑手卡、美团卡、电神卡、冰钉卡、广电卡、破冰卡、语音卡特色卡！撸卡君、玩卡君、无限卡老粉丝！专注收集与整理中国电信、中国联通、中国移动曾经现在牛卡、绝版卡，性价比极高的套餐资费卡！上车指南与行情，卡友交流与分享！中国联通广西钉钉、河北钉钉、河北政企、衡水老白、河北畅玩、河北畅享、河北畅越、河北冰爽、江西4G畅视、北京磅礴卡等
                 </p>

                <a href="https://cdn.zuime.com/app/txy.apk">
                  
                  <ButtonArea>
                    <Button>安卓APP立即下载</Button>
                  </ButtonArea>
                    
                </a>

                <br/>
                <section>
                    <p>
                        如需安装苹果版APP，请联系客服索取
                    </p>
                </section>

                <br/>
                <section>
                    <p>
                        <a href="/privacy.html">通信云用户协议与隐私协议</a>
                    </p>
                </section>

                <section>
                    <h2>版权声明</h2>
                    <p>通信云APP为北京醉么信息技术有限公司开发。版权归我司所有！</p>
                </section>
                
            
                <section>
                    <h2>联系我们</h2>
                    <p>商拓微信：13933393509</p>
                    <p>商拓电话：15733892563</p>
                </section>

        
            </Article>
           
            
            <Footer>
                <FooterLinks>
                    <FooterLink href="https://www.10055.net">通信云</FooterLink>
                    <FooterLink href="https://beian.miit.gov.cn/">京ICP备15052719号-7</FooterLink>
                </FooterLinks>
                <FooterText>Copyright &copy; 2015-2029 www.10055.net</FooterText>
            </Footer>

         </Page>
       );
    }

}


export default withRouter(DownloadScreen);