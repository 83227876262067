import React from 'react';
import {  
    Page,Flex,FlexItem,
    ButtonArea,
    Button,
    CellsTitle,
    CellsTips,
    Cell,
    CellHeader,
    CellBody,
    CellFooter,
    Form,
    FormCell,
    Icon,
    Input,
    Label,
    TextArea,
    Switch,
    Radio,
    Checkbox,
    Select,
    VCode,
    Agreement,
    Toptips} from 'react-weui';
import 'weui';
import { redirect } from 'react-router-dom'
import 'react-weui/build/packages/react-weui.css';
import axios from 'axios';
import GlobalTools from "./utils/GlobalTools";
import withRouter from "./withRouter"
import CityData from './utils/CityData';
import LTProductNumberList from './LTProductNumberList'


class LTProductScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          product:{},
          imageId1PreviewUrl:'',
          imageId1File:'',
          showId1:false,
          imageId0PreviewUrl:'',
          imageId0File:'',
          showId0:false,
          imageIdxPreviewUrl:'',
          imageIdxFile:'',
          showIdx:false,
          showBtn:true,
          provinceList:CityData[86],
          cityList:[],
          areaList:[],
          province:"",
          city:"",
          area:"",
          u: this.props.router.params.u,
          isNumsLoading:false,
          nums:[],
          searchNum:"",
          belongCityCode:-1,
          chooseNumItem:null,
          isSmsSending:false,
          smsCountDown:60,
          sms:"",
          isShowBelongCity:true,
          isShowSmsCode:true,
          isShowRule:true
        };

        console.log("持久化U");
        //持久化U
        GlobalTools.writeU(this.props.router.params.u);
        
        //确保使用https
        GlobalTools.fixHttps();  

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleProvinceChange = this.handleProvinceChange.bind(this);
        this.handleBelongProvinceChange = this.handleBelongProvinceChange.bind(this);
        this.handleCityChange = this.handleCityChange.bind(this);
        this.handleBelongCityChange = this.handleBelongCityChange.bind(this);
        this.handleAreaChange = this.handleAreaChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onSearchClick = this.onSearchClick.bind(this);
        this.onNumChoose = this.onNumChoose.bind(this);
        this.onChangeNumBatchClick = this.onChangeNumBatchClick.bind(this);
        this.onSendSmsClick = this.onSendSmsClick.bind(this);

    };

    
    componentWillMount(){
 
     }

    componentDidMount(){
        console.log("产品页面-初始化产品");
        console.log(this.props);
        var that  = this ;
        
        
        //初始化产品
        if(this.props.product){
            let {product}=this.props;
            this.setState({product:product});
            if(product.produceMode==="非直连"){
              that.setState({
                isShowSmsCode:false,
                isShowRule:false
              });
              
            }
             //检查是否需要固定归属地
             if(product.fixCityCode===null || product.fixCityCode===""){
              that.setState({
                isShowBelongCity:true
              });
            }else{
              that.setState({
                isShowBelongCity:false,
                belongProvinceCode: product.fixProvinceCode,
                belongProvinceName: product.fixProvinceName,
                belongCityCode:product.fixCityCode,
                belongCityName:product.fixCityName,
              });
  
              this.fetchNum("",product, product.fixProvinceCode,product.fixCityCode);
            }
        }else{
           this.iniProduct();
        }

        //build province data for weui
        let provinceListUI =  Object.entries( this.state.provinceList).map(x => {
            return  {value:x[0],label:x[1]}
        })

        console.log("provinceListUI");
        console.log(provinceListUI);
        provinceListUI.unshift({value:-1,label:"请选择省"})
        this.setState({
            provinceListUI:provinceListUI,
            belongProvinceListUI:provinceListUI,
            belongCityListUI:[{value:-1,label:"请选择市"}],
            cityListUI:[{value:-1,label:"请选择市"}],
            areaListUI:[{value:-1,label:"请选择区"}]
        });
    }

    iniProduct(){
        var that = this;
        let {u,id}=that.props.router.params;
        console.log(that.props.router.params);
        this.setState({u:u});
        //持久化U
        GlobalTools.writeU(u);
        var cache = GlobalTools.autoCache();
        axios.get( `/hopeapi/lt/product/${id}${cache}` )
        .then(function (response) {
            console.log("request done");
            console.log(response);
           
            if(response.data.status){
              let product = response.data.data;
              that.setState({
                product: product
              })
    
              console.log(product.productMode);
              console.log(that.props.router);


              if(product.produceMode==="非直连"){
                that.setState({
                  isShowSmsCode:false,
                  isShowRule:false
                });
                
              }


              //检查是否需要固定归属地
              if(product.fixCityCode===null || product.fixCityCode===""){
                that.setState({
                  isShowBelongCity:true
                });
              }else{
                that.setState({
                  isShowBelongCity:false,
                  belongProvinceCode: product.fixProvinceCode,
                  belongProvinceName: product.fixProvinceName,
                  belongCityCode:product.fixCityCode,
                  belongCityName:product.fixCityName,
                });
    
                //this.querynum(product.fixCityCode);
                this.fetchNum("",product, product.fixProvinceCode,product.fixCityCode);
              }
              
            }else{
              alert("此产品已下架");
              //that.props.history.push("/big?u="+u);
            }
            
        })
        .catch(function(error){
           console.log(error)
        })
    }

    handleInputChange(e){
        let o = {};
        o[e.target.name] = e.target.value;
        this.setState(o);
    }

    showWarn(warnInfo) {
      this.setState({showWarn: true,warnInfo:warnInfo});

      this.state.warnTimer = setTimeout(()=> {
          this.setState({showWarn: false,warnInfo:""});
      }, 2000);
  }
 
handleImageId1Change(e) {
    console.log("wa you want to upload");
    e.preventDefault();
      
    var reader = new FileReader();
    var file = e.target.files[0];
    
    reader.onloadend = () => {
      console.log('文件名为—',file);
      console.log('文件结果为—',reader.result);
      this.setState({
        imageId1File:file,
        imageId1PreviewUrl: reader.result,
        showId1:true
      });
    }
    
    reader.readAsDataURL(file) 
  
  }
  
  handleImageId0Change(e) {
    console.log("wa you want to upload");
    e.preventDefault();
      
    var reader = new FileReader();
    var file = e.target.files[0];
    
    reader.onloadend = () => {
      console.log('文件名为—',file);
      console.log('文件结果为—',reader.result);
      this.setState({
          imageId0File:file,
          imageId0PreviewUrl: reader.result,
          showId0:true
      });
    }
    
    reader.readAsDataURL(file) 
  
  }
  
  handleImageIdxChange(e) {
    console.log("wa you want to upload");
    e.preventDefault();
      
    var reader = new FileReader();
    var file = e.target.files[0];
    
    reader.onloadend = () => {
      console.log('文件名为—',file);
      console.log('文件结果为—',reader.result);
      this.setState({
         imageIdxFile:file,
         imageIdxPreviewUrl: reader.result,
         showIdx:true
      });
    }
    
    reader.readAsDataURL(file) 
  
  }
  
  uploadImage(api_url,file,orderCode,imageToken,nextJob){
    const formData = new FormData();
    console.log("开始上传照片------");
    // formData.append('file',value);
    formData.append('file', file)
    formData.append('token',imageToken);
    formData.append('orderCode',orderCode);
    let config = {
      method: 'post',
      headers:{'Content-Type': 'multipart/form-data'}
    }
    axios.post(api_url,formData,config).then((res) => {
      console.log("图片上传结果");
      console.log(res);
      if (res.status != 200) {
        alert("照片上传失败，请重试或者联系客服提交！");
        return false;
       }
      if (res.status === 200 && !res.data.status) {
   
        alert("照片上传失败，请重试或者联系客服提交！");
        return false;
      }
  
      //继续执行
      nextJob();
  
    }).catch((error) => {
      alert("照片上传失败，请重试或者联系客服提交！");
    })
  
  }
  
  uploadImageAll(uploadImageConfig,successJob,errorJob){
    const {api_url,imageId1File,imageId0File,imageIdxFile,orderCode,imageToken} = uploadImageConfig;
    const formData = new FormData();
    console.log("开始上传照片------");
    // formData.append('file',value);
    formData.append('id1', imageId1File);
    formData.append('id0', imageId0File);
    formData.append('idx', imageIdxFile);
    formData.append('token',imageToken);
    formData.append('orderCode',orderCode);
    let config = {
      method: 'post',
      headers:{'Content-Type': 'multipart/form-data'}
    }
    axios.post(api_url,formData,config).then((res) => {
      console.log("图片上传结果");
      console.log(res);
      if (res.status != 200) {
    
        errorJob();
        return false;
       }
      if (res.status === 200 && !res.data.status) {
   
      
        errorJob();
        return false;
      }
  
      //继续执行
      successJob();
  
    }).catch((error) => {
      errorJob();
   
    })
  
  }
  
  handleProvinceChange = (event)=>{
    const {name, value} = event.target;
    console.log("省发生变化");
    console.log(event);
     
    console.log(name);
    console.log(value);
    
    if(value===-1){
      this.setState({province:""});
      return;
    }
    this.setState({province:value,city:-1,area:-1});
   
    //反查省code
    let provinceItem = Object.entries( this.state.provinceList).filter(x => {
        console.log(x);
        return value === x[0] ;
    })
    if(provinceItem.length===0){
      this.setState({province:""});
      return;
    }
    console.log("找到省");
    console.log(provinceItem);
    console.log(CityData[provinceItem[0]]);
    this.setState({cityList:CityData[provinceItem[0][0]]});
    this.setState({provinceCode:provinceItem[0][0],provinceName:provinceItem[0][1]});

    //build weui所需数据
    let cityListUI =  Object.entries( CityData[provinceItem[0][0]] ).map(x => {
        return  {value:x[0],label:x[1]}
    })

    console.log("cityListUI");
    console.log(cityListUI);
    cityListUI.unshift({value:-1,label:"请选择市",selected:""})
    this.setState({cityListUI:cityListUI});
  }
  
  handleCityChange = (event)=>{
    const {name, value} = event.target;
    console.log(event);
    console.log(name);
    console.log(value);
    if(value===-1){
      this.setState({city:-1,area:-1});
      return;
    }
    this.setState({ city:value,area:-1});
    //反查市code
    let cityItem = Object.entries( this.state.cityList).filter(x => {
        return value === x[0] ;
    })
    if(cityItem.length===0){
      return;
    }
    console.log(cityItem);
    this.setState({areaList:CityData[cityItem[0][0]]});
    this.setState({cityCode:cityItem[0][0],cityName:cityItem[0][1]});

    //build weui所需数据
    let areaListUI =  Object.entries( CityData[cityItem[0][0]] ).map(x => {
    return  {value:x[0],label:x[1]}
    })

    console.log("areaListUI");
    console.log(areaListUI);
    areaListUI.unshift({value:-1,label:"请选择区"})
    this.setState({areaListUI:areaListUI});
  }
  
  handleAreaChange = (event)=>{
    const {name, value} = event.target;
    console.log(name);
    console.log(value);
    if(value==="请选择区"){
      //清空客户的选择
      this.setState({area:""});
      return;
    }
    this.setState({area:value});
   
    console.log(this.state.province);
    console.log(this.state.city);
    console.log(value);

     //反查市code
     let areaItem = Object.entries( this.state.areaList).filter(x => {
        return value === x[0] ;
    })
    if(areaItem.length===0){
      return;
    }
    console.log(areaItem);

    this.setState({areaCode:areaItem[0][0],areaName:areaItem[0][1]});

   
  }

  handleBelongProvinceChange = (event)=>{
    const {name, value} = event.target;
    console.log("省发生变化");
    console.log(event);
     
    console.log(name);
    console.log(value);
    
    if(value===-1){
      this.setState({province:""});
      return;
    }

    this.setState({belongProvince:value,belongCity:-1});
   
    //反查省code
    let provinceItem = Object.entries( this.state.provinceList).filter(x => {
        console.log(x);
        return value === x[0] ;
    })
    if(provinceItem.length===0){
      this.setState({province:""});
      return;
    }
    console.log("找到省");
    console.log(provinceItem);
    console.log(CityData[provinceItem[0]]);
    this.setState({belongCityList:CityData[provinceItem[0][0]]});
    this.setState({belongProvinceCode:provinceItem[0][0],belongProvinceName:provinceItem[0][1]});

    //build weui所需数据
    let belongCityListUI =  Object.entries( CityData[provinceItem[0][0]] ).map(x => {
        return  {value:x[0],label:x[1]}
    })

    console.log("belongCityListUI");
    console.log(belongCityListUI);
    belongCityListUI.unshift({value:-1,label:"请选择市",selected:""})
    this.setState({belongCityListUI:belongCityListUI});
  }

  handleBelongCityChange = (event)=>{
    const {name, value} = event.target;
    console.log(event);
    console.log(name);
    console.log(value);
    if(value===-1){
      this.setState({belongCity:-1});
      return;
    }
    this.setState({ belongCity:value});
    //反查市code
    let cityItem = Object.entries( this.state.belongCityList).filter(x => {
        return value === x[0] ;
    })
    if(cityItem.length===0){
      return;
    }
    console.log(cityItem);
    this.setState({belongCityCode:cityItem[0][0],belongCityName:cityItem[0][1]});

    this.querynum(cityItem[0][0]);

  }


  querynum =(belongCityCode)=>{
    console.log("正在加载选号数据");
    let {searchNum,product,belongProvinceCode} = this.state
    this.fetchNum(searchNum,product,belongProvinceCode,belongCityCode);
  }

  fetchNum=(searchNum,product,belongProvinceCode,belongCityCode)=>{
    var that = this;
    let postData = new FormData();
    postData.append('cityCode',belongCityCode);
    postData.append('provinceCode',belongProvinceCode);
    postData.append('goodsId',product.ltGoodsId);
    postData.append('searchValue',searchNum);
    postData.append('searchType',"01");
    that.setState({isNumsLoading:true,nums:[],chooseNum:"",chooseNumItem:null});
    
    axios.post( "/hopeapi/lt/cha", postData)
    .then(function (response) {
        console.log("request done");
        console.log(response);
        //取到了号码说明，这个产品可以选号，后续提交订单则需要验证是否完成选号
        if (response.data && response.data.status) {
            console.log("选号数据已经填充");
            that.setState({nums:response.data.data});
        }else{
            //如果是加载完成之后，出现查不到号码的情况，则提醒一下
            if(response.data.status){
                that.showWarn("没搜到，请换条件");
            }
        }
    })
    .catch(function(error){
       console.log(error)
    })
    .finally(function(){
        that.setState({isNumsLoading:false});
    });
  }

  onSearchClick =(e)=>{
    let{searchNum,belongCityCode}=this.state;
    console.log("searchNum:"+searchNum);
    if(belongCityCode===-1){
        this.showWarn("先选归属地");
        return;
    }

    if(searchNum===""){
        this.querynum(belongCityCode);
        return;
    }

    if(!/\d{1,11}/gi.test(searchNum)){
        //alert("请输入手机号码！");
        this.showWarn("仅搜数字");
        return;
    }  

    this.querynum(belongCityCode);
  }

  onNumChoose = (e)=>{
    console.log(e);
    this.setState({chooseNum:e.target.textContent})

    //反查套餐分类对象
    let chooseNumItem = Object.entries(this.state.nums).filter(x => {
    return e.target.textContent === x[1].serialNumber ;
    });
    console.log("所选号："+JSON.stringify(chooseNumItem));
    console.log(chooseNumItem);
    console.log(chooseNumItem[0][1]);
    this.setState({chooseNumItem:chooseNumItem[0][1]});
}

onChangeNumBatchClick=(e)=>{
    let{searchNum,belongCityCode}=this.state;
    console.log("searchNum:"+searchNum);
    if(belongCityCode===-1){
        this.showWarn("先选归属地");
        return;
    }
    if(searchNum===""){
        this.querynum(belongCityCode);
        return;
    }
    if(!/\d{1,11}/gi.test(searchNum)){
        //alert("请输入手机号码！");
        this.showWarn("仅搜数字");
        return;
    }  
    this.querynum(belongCityCode);
  }
  
  
  handleSubmit = (event)=>{
    event.preventDefault() //阻止表单提交
    const {
        number,idname,idnum,
        address,sms,chooseNum,isShowSmsCode
    } = this.state;
    const {
        productPrice,productVCode,
        productTitle,productMode,
        ltGoodsId,ltVCode
    } = this.state.product;
    const {
        province,city,area,
        provinceName,cityName,areaName,
        provinceCode,cityCode,areaCode,
        belongProvinceCode,belongProvinceName,
        belongCityName,belongCityCode,
    } = this.state;
    var that = this;
    console.log("可以提交数据了");
   
    if(number==="" || !/\d{11}/gi.test(number)){
          //alert("请输入手机号码！");
          that.showWarn("请输入手机号码");
          return;
      }
    
  
    if(idname==="" ){
        //alert("请输入姓名！");
        that.showWarn("请输入姓名");
        return;
    }
    
    if(idnum==="" ){
      //alert("请输入证件号码！");
      that.showWarn("请输入证件号码");
      return;
  }
    
  if(province===-1 || city===-1 || area===-1){
    //alert("请选择省市区");
    that.showWarn("请选择省市区");
    return;
  }
  
  if(address==="" ){
    //alert("请输入收货地址！");
    that.showWarn("请输入收货地址");
    return;
  }
  
  if(address.length<7 ){
    //alert("详情地址不能少于7个汉字");
    that.showWarn("详情地址不能少于7个汉字");
    return;
  }

  //仅在展示验证码的前提下，如果验证码为空，则提示
  if(isShowSmsCode && sms==="" ){
    //alert("请输入姓名！");
    that.showWarn("输入验证码");
    return;
}
  
    //最终使用地址
    let finalAddress = provinceName+cityName+areaName+address;
    //如果当前业务为需要传照片的，则检查照片是否上传
    if(productMode==="image"){
        console.log(this.state.imageId1File);
        console.log(this.state.imageId0File);
        console.log(this.state.imageIdxFile);
    
        if(!this.state.imageId1File || !this.state.imageId0File || !this.state.imageIdxFile){
            //alert("请上传照片！");
            that.showWarn("请上传照片");
            return;
        }
    
    }

    let orderdata = new FormData();
    orderdata.append('idName',idname);
    orderdata.append('idNum',idnum);
    orderdata.append('phone',number);
    orderdata.append('address',address);
    orderdata.append('productVCode',productVCode);
    orderdata.append('productTitle',productTitle);
    orderdata.append('channel',this.state.u);
    orderdata.append('provinceCode',provinceCode);
    orderdata.append('provinceName',provinceName);
    orderdata.append('cityCode',cityCode);
    orderdata.append('cityName',cityName);
    orderdata.append('areaCode',areaCode);
    orderdata.append('areaName',areaName);
    orderdata.append('newPhone',chooseNum);
    orderdata.append('belongProvinceCode',belongProvinceCode);
    orderdata.append('belongProvinceName',belongProvinceName);
    orderdata.append('belongCityCode',belongCityCode);
    orderdata.append('belongCityName',belongCityName);
    orderdata.append('ltGoodsId',ltGoodsId);
    orderdata.append('ltVCode',ltVCode);
    orderdata.append('smsCode',sms);
    orderdata.append('openId',"");

    that.setState({
        showBtn:false
    });
    axios.post("/hopeapi/lt/order",orderdata)
    .then(function (response) {
      console.log(response);
  
      if(response.status!==200){
        //alert("下单失败，稍后重试");
        that.showWarn("下单失败，稍后重试");
        that.setState({
          showBtn:true
         });
        return;
      }
    
      if(!response.data.status || response.data.status==="false"){
        alert(response.data.message);
        that.setState({
          showBtn:true
         });
        return;
      }

      that.setState({
        number:"",
        idname:'',
        idnum:"",
        address:"",
        province:-1,
        city:-1,
        area:-1
      });
      //如产品需要提交照片，则开始上传照片
      if(productMode==='image'){
          //"/hopeapi/biz/upload/id1"
          //  api_url,imageId1File,imageId0File,imageIdxFile,orderCode,imageToken
          var uploadImageConfig={
            api_url: "/hopeapi/biz/upload/ids",
            imageId1File: that.state.imageId1File,
            imageId0File: that.state.imageId0File,
            imageIdxFile: that.state.imageIdxFile,
            orderCode: response.data.data.orderCode,
            imageToken: response.data.data.imageToken
          }
          that.uploadImageAll(uploadImageConfig,function(){
            if(productPrice>0){
  
              that.setState({
                showBtn:true,
                showId1:false,
                showId0:false,
                showIdx:false
               });
               that.props.history.push("/pay/"+response.data.data.orderCode+"/"+response.data.data.imageToken);
            }
          },function(){
  
             //alert("照片上传失败，请重试或者联系客服提交！");
            that.setState({
              showBtn:true
             });
           
             that.props.history.push("/pay/"+response.data.data.orderCode+"/"+response.data.data.imageToken);
          });
  
      }else{
       
        if(productPrice>0){
          //alert("您下单的是付费产品，请联系页面底部平台客服，支付费用。");
          //跳到付款提示页面，拍出付款码/付款按扭
  
          that.setState({
            showBtn:true
           });
 
           that.props.router.navigate("/ok");
        }else{
          //alert(response.data.message);
          that.props.router.navigate("/ok");
        }
      }
     
    });
  }
  

  onSendSmsClick =(e)=>{

    console.log("发送短信验证码");
    var that = this;
    let {isSmsSending,number,idname,idnum,product} = that.state;
    //正在发送
    if(isSmsSending){
      return;
    }

    if(number==="" || !/\d{11}/gi.test(number)){
        //alert("请输入手机号码！");
        that.showWarn("请输入手机号码");
        return;
    }
 
    if(idnum==="" ){
        //alert("请输入证件号码！");
        that.showWarn("请输入证件号码");
        return;
    }

    let orderdata = new FormData();
    orderdata.append('vcode',product.ltVCode);
    orderdata.append('certNo',idnum);
    orderdata.append('contactNum',number);
    axios.post("/hopeapi/lt/sms",orderdata).then(function (response) {
         console.log(response);
    }).finally(function(){
         console.log("Done");
    });

    //开始倒计时
    that.setState({isSmsSending:true,smsCountDown:60});
    var smsCountDown = 60;
    var smsCountDownInterval = setInterval(function(){
      smsCountDown--;
      that.setState({smsCountDown:smsCountDown});
      if(smsCountDown<1){
        clearInterval(smsCountDownInterval);
        that.setState({isSmsSending:false});
      }
    }, 1000);

  }

    
    render() {
        var that = this;
        let{nums,chooseNum,chooseNumItem,isNumsLoading,smsCountDown,isShowBelongCity,isShowSmsCode,isShowRule}=this.state;
        let {onNumChoose,onSearchClick,handleInputChange,onChangeNumBatchClick,onSendSmsClick} = this;

        return (
            <Page className="input" title="号码宝特色卡种" subTitle="联通电信移动广电优惠套餐办理" infiniteLoader={false}
             ptr={false}  >       
            <img src={this.state.product.productCoverUrl} style={{width:"100%"}}/>
            <div className='hmbProductTitle'>{this.state.product.productTitle}</div>

           {isShowBelongCity?<>
              <CellsTitle>归属地</CellsTitle>
              <Form>
                  <FormCell select selectPos="after">
                      <CellHeader>
                          <Label>省/直辖市</Label>
                      </CellHeader>
                      <CellBody>
                          <Select data={this.state.belongProvinceListUI}  onChange={this.handleBelongProvinceChange} />
                      </CellBody>
                  </FormCell>

                  <FormCell select selectPos="after">
                      <CellHeader>
                          <Label>市</Label>
                      </CellHeader>
                      <CellBody>
                          <Select data={this.state.belongCityListUI} value={this.state.belongCity}  onChange={this.handleBelongCityChange} />
                      </CellBody>
                  </FormCell>

              </Form>
           </>:""}
           

            <LTProductNumberList 
            nums={nums} 
            chooseNum={chooseNum}
            chooseNumItem = {chooseNumItem}
            isShowChooseNums={true} 
            onSearchClick = {onSearchClick}
            handleInputChange ={handleInputChange}
            onNumChoose={onNumChoose}
            isNumsLoading={isNumsLoading}
            onChangeNumBatchClick = {onChangeNumBatchClick}
            isShowRule={isShowRule}

            ></LTProductNumberList>


            <CellsTitle>基本信息</CellsTitle>
            <Form>
                    <FormCell>
                        <CellHeader>
                            <Label>姓名</Label>
                        </CellHeader>
                        <CellBody>
                            <Input type="text" placeholder="输入姓名" onChange={this.handleInputChange}  name="idname"/>
                        </CellBody>
                    </FormCell>

                    <FormCell>
                        <CellHeader>
                            <Label>身份证号</Label>
                        </CellHeader>
                        <CellBody>
                            <Input type="text" placeholder="输入身份证号"  onChange={this.handleInputChange}   name="idnum" />
                        </CellBody>
                    </FormCell>

                    <FormCell>
                        <CellHeader>
                            <Label>联系号码</Label>
                        </CellHeader>
                        <CellBody>
                            <Input type="text" placeholder="输入联系号码"  onChange={this.handleInputChange}   name="number" />
                        </CellBody>
                    </FormCell>

                 
            </Form>

            <CellsTitle>收货地址</CellsTitle>
            <Form>
                 <FormCell select selectPos="after">
                    <CellHeader>
                        <Label>省/直辖市</Label>
                    </CellHeader>
                    <CellBody>
                        <Select data={this.state.provinceListUI}  onChange={this.handleProvinceChange} />
                    </CellBody>
                </FormCell>

                <FormCell select selectPos="after">
                    <CellHeader>
                        <Label>市</Label>
                    </CellHeader>
                    <CellBody>
                        <Select data={this.state.cityListUI} value={this.state.city}  onChange={this.handleCityChange} />
                    </CellBody>
                </FormCell>

                <FormCell select selectPos="after">
                    <CellHeader>
                        <Label>区</Label>
                    </CellHeader>
                    <CellBody>
                        <Select data={this.state.areaListUI} value={this.state.area} onChange={this.handleAreaChange} />
                    </CellBody>
                </FormCell>

                <FormCell>
                        <CellHeader>
                            <Label>详情地址</Label>
                        </CellHeader>
                        <CellBody>
                            <Input type="text" placeholder="至少7个汉字 勿重复省市区" onChange={this.handleInputChange}   name="address" />
                        </CellBody>
               </FormCell>



               {isShowSmsCode?<>
                <FormCell vcode>
                          <CellHeader>
                              <Label>验证码</Label>
                          </CellHeader>
                          <CellBody>
                              <Input type="text" placeholder="请输入验证码"   onChange={handleInputChange}  name="sms"  />
                          </CellBody>
                          <CellFooter>
                              <Button type="vcode" onClick={onSendSmsClick}>
                                {!this.state.isSmsSending?"发送":smsCountDown}
                                </Button>
                          </CellFooter>
                  </FormCell>
           </>:""}
           



 

           </Form>
            
            <ButtonArea>
                     <Button onClick={this.handleSubmit}  style={{ display: this.state.showBtn?'block':'none' }}>
                         {this.state.product.productPrice}元{this.state.product.productBtn}
                     </Button>


                     <Button style={{ display: !this.state.showBtn?'block':'none' }}>
                        正在提交，请稍后
                    </Button>


            </ButtonArea>
            
            <img src={this.state.product.productDetailUrl} style={{width:"100%",marginTop: '0.5em'}}/>
            <img src="/warnnotice.jpg" style={{width:"100%"}}/>

            <Toptips type="warn" show={this.state.showWarn}> {this.state.warnInfo} </Toptips>
         </Page>
       );
    }

}


export default withRouter(LTProductScreen);