
import logo from './logo.svg';
import './App.css';
import './HomeScreen.css';
import React from 'react';
import { Button,Page,Grids,Flex,FlexItem,   Footer,
    FooterText,
    FooterLinks,
    FooterLink,NavLink,InfiniteLoader, ButtonArea} from 'react-weui';
import 'weui';
import 'react-weui/build/packages/react-weui.css';
import HomeGrid from './HomeGrid';
import axios from 'axios';
import ProductList from "./ProductList";
import GlobalTools from "./utils/GlobalTools";
import withRouter from "./withRouter"


class HomeScreen extends React.Component {

    constructor(props) {
        super(props);
        console.log("构造器初始化");
        console.log(this);
        var that  = this ;
        GlobalTools.autoU(this.props.router.location.search,function(context){
            console.log("得到U:"+context.u);
            that.state = { 
                leftProducts:[],
                rightProducts:[],
                rawProducts:[],
                leftHeight:0,
                rightHeight:0,
                page:1,
                filter:"所有",
                u:context.u}

            //console.log("构造器中修复url");
            //GlobalTools.fixUrl(context.u);
        });

        

        this.onLoadMore = this.onLoadMore.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
    };

    
    componentWillMount(){

     }

    componentDidMount(){
        //确保使用https
        GlobalTools.fixHttps(); 
        GlobalTools.fixUrl(this.state.u);
        //初始化
        this.initProductList();
    }

    async initProductList(){
        console.log("初始化产品列表");
        var that = this;
        var cache = GlobalTools.autoCache();
        await axios.get( `/hopeapi/product/pub/list${cache}` )
        .then( function (response) {
            console.log("request done");
            //console.log(response);
            if (response.data.status && response.data.data && response.data.data.length > 0) {
                const dataArry = response.data.data || []
                //that.setState({rawProducts:dataArry});
                that.buildDisplayProductList(dataArry,that);
            }
        })
      
    }

    productsFilter(dataArry,targetFitler){

        if(targetFitler === "所有"){

            return  dataArry;
        }

        console.log("需要过滤产品");
        //对产品进行过滤
        var filterDataArray = dataArry.filter((x)=>{

            return x.productTitle.indexOf(targetFitler)>-1;
        })

        return filterDataArray;
    }

    async buildDisplayProductList(dataArry,that){
        const leftProducts = that.state.leftProducts
        const rightProducts = that.state.rightProducts

        console.log("进入渲染");
        let targetDataArray = that.productsFilter(dataArry,that.state.filter);
        console.log(targetDataArray);

        for (const item of targetDataArray.values()) {
                    
                    //console.log(item);
                    if (that.state.leftHeight <=  that.state.rightHeight) {
                        //console.log("right");
                        leftProducts.push(item)
                    } else {
                        //console.log("right");
                        rightProducts.push(item)
                    }
                    
                    //更新两边的高度
                    await  ((context,leftList, rightList)=>{
                        return new Promise((resolve) => {
                            context.setState({
                                leftList,
                                rightList
                            }, () => {
                                //统计高度 左侧
                                var leftItemDoms = document.querySelectorAll('#leftList .pro-item');
                                var calLeftHeight= 0;
                                leftItemDoms.forEach(item=>{
                                    //console.log(item);
                                    let  leftQuery = item.getBoundingClientRect();
                                    calLeftHeight =calLeftHeight+leftQuery.height;
                                });


                                //统计高度 右侧
                                var rightItemDoms = document.querySelectorAll('#rightList .pro-item');
                                var calRightHeight= 0;
                              
                                rightItemDoms.forEach(item=>{
                                    //console.log(item);
                                    let  rightQuery = item.getBoundingClientRect();
                                    calRightHeight = calRightHeight + rightQuery.height;
                                });

                                context.setState({
                                    leftHeight:calLeftHeight,
                                    rightHeight:calRightHeight

                                });

                                //console.log("calLeftHeight:"+calLeftHeight);
                                //console.log("rightHeight:"+calRightHeight);
                                resolve();
                            })
                        })
                      })(that,leftProducts, rightProducts);
       }  
    }

    onLoadMore = (resolve, finish) => {
        console.log("加载更多列表");
        var page = this.state.page+1;
        var that  = this;
        var cache = GlobalTools.autoCache();

        axios.get( `/hopeapi/product/pub/list/${page}${cache}`)
        .then(async function (response) {
            console.log("request done");
            console.log(response);
            if (response.data.status && response.data.data ) {
                const dataArry = response.data.data || []
                if(response.data.data.length==0){
                    finish();
                }
                
                //that.setState({rawProducts:[...that.state.rawProducts,...dataArry]});
                //保存一份原始数据，方便进行过滤处理
                if(response.data.data.length>0 && response.data.data.length<100){
                    that.setState({page:page});
                    that.buildDisplayProductList(dataArry,that);
                    finish();
                }
                if(response.data.data.length>=100){
                    that.setState({page:page});
                    that.buildDisplayProductList(dataArry,that);
                    resolve();
                }
                
            }
        })

    };

    onFilterChange = (e)=>{
        var that = this;
        console.log(e);
        //重复点击忽略
        if(this.state.filter===e.target.textContent){
            return ;
        }

        console.log(e.target.textContent);

        that.setState({
            leftProducts:[],
            rightProducts:[],
            rawProducts:[],
            leftHeight:0,
            rightHeight:0,
            page:1,
            filter:e.target.textContent
          });

       that.initProductList();
        
    }

    render() {
        var that = this;
        let {filter,u} = this.state;
        return (
            <Page className="infinite" title="号码宝" subTitle="联通电信移动广电优惠套餐办理" infiniteLoader={true}
             onLoadMore={(resolve, finish) => this.onLoadMore(resolve, finish)} ptr={false}  >
             <HomeGrid u={u}/>
             <ButtonArea>
                <Button size="small" type={filter==="所有"?"primary":"default"} onClick={this.onFilterChange} >所有</Button>
                <Button size="small" type={filter==="联通"?"primary":"default"} onClick={this.onFilterChange}  >联通</Button>
                <Button size="small" type={filter==="电信"?"primary":"default"} onClick={this.onFilterChange}  >电信</Button>
                <Button size="small" type={filter==="移动"?"primary":"default"} onClick={this.onFilterChange}  >移动</Button>
                <Button size="small" type={filter==="广电"?"primary":"default"} onClick={this.onFilterChange}  >广电</Button>
             </ButtonArea>
           
             <Flex >
                 <FlexItem className="pro-box-left" id="leftList">
                        <ProductList products={ that.state.leftProducts}  u={u}></ProductList>
                 </FlexItem>
                 <FlexItem className="pro-box-right" id="rightList">
                         <ProductList products={ that.state.rightProducts} u={u}></ProductList>
                 </FlexItem>
             </Flex>


             <Footer>
                <FooterLinks>
                    <FooterLink href="https://www.10055.net">号码宝</FooterLink>
                    <FooterLink href="https://beian.miit.gov.cn/">京ICP备15052719号-9</FooterLink>
                    <FooterLink href="https://beian.miit.gov.cn/">京ICP备15052719号-7</FooterLink>
                    <FooterLink href="https://beian.miit.gov.cn/">京ICP备15052719号-12</FooterLink>
                </FooterLinks>
                <FooterText>Copyright &copy; 2015-2029 www.10055.net</FooterText>
            </Footer>
           
         </Page>
       );
    }

}


export default withRouter(HomeScreen) ;