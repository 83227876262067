import { Grids} from 'react-weui';
 



function BusGrid(props) {

    let {u} = props;

    const data = [{
        icon: <img src={'/images/customer_head_phones_service_icon.svg'}/>,
        label: '在线搭桥',
        href: `/trade/kefu/${u}`
    },
    
    {
        icon: <img src={'/images/subscribe.svg'}/>,
        label: '通知订阅',
        href: `/trade/subscribe/${u}`
    },
    {
        icon: <img src={'/images/arrow-right-arrow-left-solid.svg'}/>,
        label: '过户说明',
        href: `/trade/intro/${u}`
    },
    ];


    return (
        <div>
            <Grids data={data} className="trade-grid"/>
        </div>
    );
  }
  
  export default BusGrid;