
import { BrowserRouter as Router,Routes, Route} from "react-router-dom";
import HomeScreen from './HomeScreen';
import ProductScreen from "./ProductScreen";
import NotFoundScreen from "./NotFoundScreen";
import ProductOKScreen from "./ProductOKScreen";
import ChaScreen from './ChaScreen';
import CoScreen from './CoScreen'
import TradeIndexScreen from './trade/IndexScreen'
import TradeBusScreen from './trade/BusScreen'
import TradeNewScreen from './trade/NewScreen'
import TradeSubscribeScreen from './trade/SubscribeScreen'
import TradeMyScreen from './trade/MyScreen'
import TradeMsgScreen from './trade/MsgScreen'
import TradeIntroScreen from './trade/IntroScreen'
import TradeKefuScreen from './trade/KefuScreen'
import TianshiScreen from './TianshiScreen'
import NNProductScreen from './NNProductScreen'
import GDProductScreen from './GDProductScreen'
import DownloadScreen from './DownloadScreen'
import LTProductScreen from './LTProductScreen'
import KHProductScreen from './KHProductScreen'
import TingScreen from './TingScreen'
import LoginScreen from './LoginScreen'


function App() {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<LoginScreen />} />
          <Route path="/ting/:u" element={<TingScreen/>}  animate={true}/>
          <Route path="/ka/:id/:u" element={<ProductScreen/>}  animate={true}/>
          <Route path="/product/:id/:u" element={<ProductScreen/>}  animate={true}/>
          <Route path="/gdproduct/:id/:u" element={<GDProductScreen/>}  animate={true}/>
          <Route path="/khproduct/:id/:u" element={<KHProductScreen/>}  animate={true}/>
          <Route path="/ltproduct/:id/:u" element={<LTProductScreen/>}  animate={true}/>
          <Route path="/oproduct/:id/:u" element={<ProductScreen/>}  animate={true}/>
          <Route path="/ssproduct/:id/:u" element={<ProductScreen/>}  animate={true}/>
          <Route path="/nnproduct/:id/:u" element={<NNProductScreen/>}  animate={true}/>
          <Route path="/cha/:u" element={<ChaScreen/>}  animate={true}/>
          <Route path="/download/:u" element={<DownloadScreen/>}  animate={true}/>
          
          <Route path="/ok" element={<ProductOKScreen />} animate={true}/>
          <Route path="/trade/bus/:id/:u" element={<TradeBusScreen/>}  animate={true}/>
          <Route path="/trade/index/:u" element={<TradeIndexScreen/>} animate={true}/>
          <Route path="/trade/new/:u" element={<TradeNewScreen/>} animate={true}/>
          <Route path="/trade/subscribe/:u" element={<TradeSubscribeScreen/>} animate={true}/>
          <Route path="/trade/my/:u" element={<TradeMyScreen/>} animate={true}/>
          <Route path="/trade/msg/:u" element={<TradeMsgScreen/>} animate={true}/>
          <Route path="/trade/intro/:u" element={<TradeIntroScreen/>} animate={true}/>
          <Route path="/trade/kefu/:u" element={<TradeKefuScreen/>} animate={true}/>
          <Route path="/tianshi/:u" element={<TianshiScreen/>} animate={true}/>
          <Route path="/co/:u" element={<CoScreen />}  animate={true}/>
          <Route path="/*" element={<NotFoundScreen />} />
        </Routes>
      </Router>
    );
}
  
export default App;
